import React, { Component } from "react";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/defaultProduct.jpg";
import { connect } from "react-redux";
import { removeFromCart, incrementQty, decrementQty, updateToken, addMyCart, addToCart } from "../../actions";
import ProductModal from "../collection/common/productModal";
import * as GENERAL from "../../api/general";
import { toast } from "react-toastify";

class ProductItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            stock: "InStock",
            quantity: props.cartItems.find((item) => item.pid == props.product.id)
                ? props.cartItems.find((item) => item.pid == props.product.id).qty
                : 1,
            image: "",
            from: props.from ? props.from : 0,
            product: props.product,
            selectedOptionID: this.props.product.data_option ? "0" : "none",
            nowTimeString: '',
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        let date = new Date();
        this.setState({ nowTimeString: Date.parse(date) });
    }

    resize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    onClickHandle(img) {
        this.setState({ image: img });
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false, selectedOptionID: "0" });
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({ quantity: this.state.quantity - 1, product: { ...this.state.product, qty: this.state.quantity - 1 } });
        }
    };

    plusQty = () => {
        if (this.state.quantity != 100) {
            this.setState({ quantity: this.state.quantity + 1, product: { ...this.state.product, qty: this.state.quantity + 1 } });
        } else {
            // this.setState({stock: 'Out of Stock !'})
        }
    };

    changeQty = (e, optionID) => {
        let prevQty = this.state.prevQty;
        let list = this.props.cartItems;
        const { product } = this.state;
        let input = e.target.value;
        if (parseInt(input) < 0) {
            input = -input;
        }
        let item = optionID
            ? this.props.cartItems.find((item) => item.pid == product.id && item.selected_pov && item.selected_pov.id == optionID)
            : this.props.cartItems.find((item) => item.pid == product.id);

        if (e.target.value == "") {
            prevQty = list.find((cartItem) => cartItem.pid == item.pid).qty;
        }
        list.find((cartItem) => cartItem.pid == item.pid).qty = e.target.value;
        item.qty = input;

        this.setState({ cartList: list, prevQty: prevQty }, () => {
            if (item.qty !== "0" && item.qty.length > 0) {
                this.props.addToCart(item, 0, this.props);
            }
            // if (item.qty == "0") {
            //   this.onOpenModal(item);
            // }
        });
    };

    updateQty = (prod, type, option) => {
        if (option) {
            let oriPrice = parseFloat(
                prod.is_promotion == 1 && prod.price.pprice > 0 && prod.price.rprice !== prod.price.pprice
                    ? prod.price.pprice
                    : prod.price.rprice
            );
            let extraPrice = parseFloat(option.extra_price);
            let total = oriPrice + extraPrice;
            prod.finalPrice = parseFloat(total).toFixed(2);
            prod.selected_pov = option;
        }
        let item = option
            ? this.props.cartItems.find((item) => item.pid == prod.id && item.selected_pov && item.selected_pov.id == option.id)
            : this.props.cartItems.find((item) => item.pid == prod.id);
        if (type == "minus") {
            if ((item ? item.qty : 0 + prod.qty) - 1 == 0) this.props.removeFromCart(item, this.props);
            else this.props.decrementQty(item, this.props);
        } else if (type == "plus") {
            if (item.qty < prod.stock_quantity) {
                this.props.incrementQty(item, 1, this.props);
            } else {
                toast.error("Out of Stock", { autoClose: 3000, position: "top-center" });
            }
        } else {
            this.props.onAddToCartClicked(prod, 1);
        }
    };

    returnTotalQty(product) {
        let total = 0;
        this.props.cartItems
            .filter((item) => item.pid == product.id)
            .map((prodOption) => {
                total += prodOption.qty;
            });
        return total;
    }

    render() {
        const { symbol, isDealer, addToCart, onAddToCartClicked, cartItems, history, page } = this.props;
        const { open, product, quantity, selectedOptionID } = this.state;
        return (
            <div className={product.cart == true ? "product-box" : "product-box product-box-unavailable"}>
                <div className="img-wrapper">
                    <Link
                        to={{
                            pathname: `${process.env.PUBLIC_URL}/product/${product.slug}`,
                            state: { location: window.location.pathname, page: page },
                        }}>
                        <div className="front mb-2">
                            {/* <div className="lable-block">
                            {product.is_promotion == 1 ? (
                              <span className="lable3 home">{parseFloat(((product.price.rprice - product.price.pprice) / product.price.rprice) * 100).toFixed(0)} %</span>
                            ) : product.stock_status == 2 ? (
                              <span className="lable3 home">New</span>
                            ) : (
                              ""
                            )}
                          </div> */}
                            <div className="recommend-label-block">
                                {product.is_featured == 1 ? (
                                    <img
                                        className="recommend-label"
                                        src={`${process.env.PUBLIC_URL}/assets/images/products/recommend.png?` + this.state.nowTimeString}
                                        style={{ height: 40, width: 40 }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            {/* <div>
                            {product.is_latest == 1 ? (
                              <img className="new-label" src={`${process.env.PUBLIC_URL}/assets/images/products/new.png`} style={{height: 40, width: 40}}/>
                            ) : (
                              ""
                            )}
                          </div> */}
                            <div>
                                {product.is_latest == 1 ? (
                                    <img
                                        className="new-label"
                                        src={`${process.env.PUBLIC_URL}/assets/images/products/new.png`}
                                        style={{ height: 40, width: 40 }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <img
                                ref={(img) => (this.img = img)}
                                onError={() => (this.img.src = noImg)}
                                src={`${product.image ? (product.image + '?' + this.state.nowTimeString) : noImg}`}
                                className="img-fluid"
                                alt={product.name}
                            />
                        </div>
                    </Link>
                    <div className="product-detail">
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.slug}`}>
                            <div>
                                <h6>{product.name ? product.name : product.product_name}</h6>
                                {isDealer && product.dealer_price && parseFloat(product.dealer_price) > 0 ? (
                                    //dealer price
                                    <h4>
                                        <span className="money">
                                            {symbol}
                                            {product.dealer_price}
                                        </span>
                                    </h4>
                                ) : //normal customer price
                                product.is_promotion == 1 &&
                                  parseFloat(product.price.pprice) > 0 &&
                                  product.price.rprice !== product.price.pprice ? (
                                    <h4>
                                        {symbol}
                                        {product.price.pprice}
                                        <del>
                                            <span className="money">
                                                {symbol}
                                                {product.price.rprice}
                                            </span>
                                        </del>
                                    </h4>
                                ) : (
                                    <h4>
                                        <span className="money">
                                            {symbol}
                                            {product.price.rprice}
                                        </span>
                                    </h4>
                                )}
                            </div>
                        </Link>
                    </div>
                </div>
                {cartItems.find((item) => item.pid == product.id && item.qty > 0) ? (
                    <div className={`qty-box cart_qty ${1 ? "open" : ""}`}>
                        <div className="input-group" style={{ flexWrap: "nowrap" }}>
                            <span className="input-group-prepend w-25 mr-0">
                                <button
                                    type="button"
                                    className="btn quantity-left-minus bg-theme w-100"
                                    onClick={selectedOptionID !== "none" ? this.onOpenModal : () => this.updateQty(product, "minus")}
                                    data-type="minus"
                                    data-field="">
                                    <i className="fa fa-minus" style={{ color: "white" }} />
                                </button>
                            </span>
                            {product.data_option ? (
                                <span className="form-control input-number" style={{ flex: "none", width: "50%" }}>
                                    {this.returnTotalQty(product)}
                                </span>
                            ) : (
                                <input
                                    type="text"
                                    required
                                    name="quantity"
                                    value={cartItems.find((item) => item.pid == product.id).qty}
                                    onChange={this.changeQty}
                                    maxLength={3}
                                    style={{ flex: "none", width: "50%" }}
                                    className="form-control input-number"
                                />
                            )}
                            <span className="input-group-prepend w-25 mr-0">
                                <button
                                    type="button"
                                    className="btn quantity-right-plus bg-theme w-100"
                                    onClick={selectedOptionID !== "none" ? this.onOpenModal : () => this.updateQty(product, "plus")}
                                    data-type="plus"
                                    data-field="">
                                    <i className="fa fa-plus" style={{ color: "white" }} />
                                </button>
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="addtocart_btn">
                        {product.cart == true ? (
                            <button
                                className="add-button add_cart"
                                title="Add to cart"
                                onClick={
                                    selectedOptionID !== "none" ? this.onOpenModal : () => onAddToCartClicked(product, this.state.quantity)
                                }>
                                add to cart
                            </button>
                        ) : (
                            <button
                                onClick={
                                    product.stock_status == "16"
                                        ? () => history.push({ pathname: "/contact-us", state: { productName: product.name } })
                                        : null
                                }
                                disabled={product.stock_status !== "16"}
                                className={product.stock_status == "16" ? "add-button add_cart" : "add-button disabled-btn"}>
                                {GENERAL.returnStatusName(product.stock_status)
                                    ? GENERAL.returnStatusName(product.stock_status)
                                    : "Out Of Stock"}
                            </button>
                        )}
                    </div>
                )}
                <ProductModal
                    open={open}
                    onCloseModal={this.onCloseModal}
                    product={product}
                    symbol={symbol}
                    changeQty={this.changeQty}
                    cartItems={cartItems}
                    updateQty={this.updateQty}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
});

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty, updateToken, addToCart, addMyCart }
)(ProductItem);
