import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import * as PROFILE from "../../api/profile";
import * as CART from "../../api/cart";
import * as ORDER from "../../api/order";
import * as AUTH from "../../api/auth";
import * as GENERAL from "../../api/general";
import * as DOWNLINE from "../../api/downline";
import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import { addMyCart, updateToken } from "../../actions/index";
import Select from "react-select";
import Cookies from "universal-cookie";

const re = /^[0-9\b]+$/;
const reZip = /^\d{5}$/;
const stateArr = GENERAL.getStateList();
const itemPackingList = [{ id: 1, name: "Yes" }, { id: 0, name: "No" }];
const titleList = [{ id: "1", name: "Mr" }, { id: "2", name: "Ms" }, { id: "3", name: "Mrs" }, { id: "4", name: "Madam" }];
const purchaseByList = [{ id: 1, name: "Own" }, { id: 2, name: "Downline" }, { id: 3, name: "One Time Customer" }];
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0px 8px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    height: "auto",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "0px",
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "#B7D432",
    padding: "unset !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: "0px",
    paddingBottom: "0px",
    margin: "0px",
  }),
};
const cookies = new Cookies();
class checkOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "0",
      fname: "",
      lname: "",
      mobileno: "",
      email: "",
      country: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      billing: 0,
      shipping: 0,
      street1: "",
      street2: "",
      selectedAddress: [],
      addressList: [],
      payMethod: [],
      myCart: [],
      shippingAddress: "",
      open: false,
      failMsg: [],
      defaultShip: "",
      defaultBill: "",
      loading: true,
      errShip: "",
      selectedMethod: 0,
      shippingFee: 0,
      selectedBillID: 0,
      mode: "",
      noAddress: false,
      newAddressID: 0,
      firstLoad: true,
      postData: {},
      selectedRole: 1, // 1 = normal user | 2 = downline | 3 = one time customer | 4 = guest
      selectedOption: null,
      memberList: [],
      memberAddressList: [],
      downlineCartItems: [],
      input: "",
      useSameAddress: true, // one time customer who use same address for billing & shipping
      checkoutId: "", // RM payment use field
      shippingMethodID: 1,
      itemPack: 0, // radio button value of item 1 = pack or 2 = unpack
      packingFee: null,
      oneTimeAddress: null,
      shippingMethodList: [], //shipping method list get from backend
      buyerEmail: "",
      remarks: "",
      hasShippingTimeSlot: false,
      shippingTimeSlot: [],
      selectedDate: 0,
      selectedTimeSlot: 0,
      shippingDateArr: [],
      shippingTimeList: {},
      shippingTimeArr: [],
      shippingAddress: [],
      availabilityErrMsg: "",
      proceedCheckout: false,
      createAcc: false,
      createAccErr: false,
      accName: "",
      accMobile: "",
      partner: null,
      deliveryHub: [],
      partnerServiceCharge: 0,
      partnerServiceChargeName: "",
      registerPassword: "",
      registerConfirmPassword: "",
      registerErrorMsg: "",
      selectedOutStockOption: 0,
      outStockOptionError: false,
    };
    this.validator = new SimpleReactValidator();
    this.oneTimeFormRef = React.createRef();
    CART.getLatestCart("checkout");
  }

  componentWillMount() {
    ORDER.getPaymentMethod().then((method) => {
      if (method.data && method.data[0].bypass) {
        this.setState({
          selectedMethod: "bypass",
        });
      } else {
        this.setState({
          payMethod: method.data,
          selectedMethod: method.data[0].id,
        });
      }
    }).catch((err) => {
    });

    //let referral = JSON.parse(localStorage.getItem("referral"));

    if (cookies.get('partner-referral')) {
      this.setState({
        partner: cookies.get('partner-referral')
      });
    }

    this.setState({
      shippingAddress: JSON.parse(localStorage.getItem("shipping"))
    });

    if (this.props.token) {
      this.checkAddress();
      if (this.props.isDealer) this.getDownlineList();
    } else {
      this.setState(
        {
          selectedRole: 4,
        },
        () => {
          // this.getShippingMethod();
        }
      );
      this.setState({ loading: false });
    }
    ORDER.getShippingTimeSlot().then((shippingTimeSlot) => {
      if (shippingTimeSlot.data && Array.isArray(shippingTimeSlot.data) && shippingTimeSlot.data.length) {
        let shippingDateArr = [];
        let shippingTimeList = {};
        for (let i = 0; i < shippingTimeSlot.data.length; i++) {
          shippingDateArr[shippingTimeSlot.data[i].day] = shippingTimeSlot.data[i].info;
          if (shippingTimeSlot.data[i].slot && Array.isArray(shippingTimeSlot.data[i].slot)) {
            shippingTimeList[shippingTimeSlot.data[i].day] = shippingTimeSlot.data[i].slot;
          }
        }
        var shippingTimeArr = shippingTimeList[shippingTimeSlot.data[0].day];
        this.setState({
          hasShippingTimeSlot: true,
          shippingTimeSlot: shippingTimeSlot.data,
          shippingDateArr: shippingDateArr,
          shippingTimeList: shippingTimeList,
          shippingTimeArr: shippingTimeArr,
          selectedDate: shippingTimeSlot.data[0].day,
          selectedTimeSlot: shippingTimeSlot.data[0].slot[0].slot_id,
        });
      }/* else {
        this.setState({
          hasShippingTimeSlot: false,
          shippingTimeSlot: [],
        });
      }*/
    });

  }

  componentDidUpdate(prevProps) {
    // recalculate fee if the cart item being changes
    if (prevProps.cartItems.length !== this.props.cartItems.length && this.props.cartItems.length !== 0) {
      let newFormData = new FormData();
      newFormData.append("shipping_address_id", this.state.defaultShip.id);
      for (let i = 0; i < this.props.cartItems.length; i++) {
        newFormData.append("item[" + i + "][cart_id]", this.props.cartItems[i].cid);
        newFormData.append("item[" + i + "][pid]", this.props.cartItems[i].pid);
        newFormData.append("item[" + i + "][qty]", this.props.cartItems[i].qty);
        newFormData.append("item[" + i + "][pov_id]", this.props.cartItems[i].selected_pov ? this.props.cartItems[i].selected_pov.id : null);
      }
      this.getShippingFee(newFormData);
    }
  }

  getState(state) {
    for (let i = 0; i < stateArr.length; i++) {
      if (state == Object.keys(stateArr[i]).toString()) return Object.values(stateArr[i]).toString();
    }
  }

  tokenExpiredHandle() {
    AUTH.logout(this.props);
    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
    this.props.history.replace("/login");
  }

  checkAddress() {
    PROFILE.getAddress()
      .then(async (res) => {
        let billingAddress = {};
        let shippingAddress = {};
        let addressArr = [],
          list = res.data;
        if (this.state.noAddress) {
          //user no address
          this.setState({ noAddress: false });
          billingAddress = res.data[0];
          shippingAddress = res.data[0];
          addressArr.push({ id: res.data[0].id, address: this.convertToAddress(res.data[0]) });
          this.setState(
            {
              addressList: list,
              addressArr: addressArr,
              defaultShip: shippingAddress,
              defaultBill: billingAddress,
              selectedBillID: billingAddress.id,
              selectedBillAddress: { id: billingAddress.id, address: this.convertToAddress(billingAddress) },
              selectedAddress: { id: shippingAddress.id, address: this.convertToAddress(shippingAddress) },
            },
            () => {
              this.getShippingMethod();
            }
          );
        } else {
          if (res.data && res.data.length > 0) {
            let updateAddress = false;
            if (this.state.mode == "addBill") {
              billingAddress = res.data.find((add) => add.id == this.state.newAddressID);
              shippingAddress = this.state.selectedAddress;
              updateAddress = true;
            } else if (this.state.mode == "addShip") {
              billingAddress = this.state.selectedBillAddress;
              shippingAddress = res.data.find((add) => add.id == this.state.newAddressID);
              updateAddress = true;
            } else {
              billingAddress = res.data.find((add) => add.billing == "1");
              shippingAddress = res.data.find((add) => add.shipping == "1");
            }
            if (!billingAddress) {
              billingAddress = res.data[0];
            }
            if (!shippingAddress) {
              shippingAddress = res.data[0];
            }
            for (let i = 0; i < res.data.length; i++) {
              addressArr.push({ id: res.data[i].id, address: this.convertToAddress(res.data[i]) });
            }
            this.setState(
              {
                addressList: list,
                addressArr: addressArr,
                defaultShip: this.state.mode !== "addShip" && this.state.defaultShip !== "" ? this.state.defaultShip : shippingAddress,
                defaultBill: this.state.mode !== "addBill" && this.state.defaultBill !== "" ? this.state.defaultBill : billingAddress,
                selectedBillID: billingAddress.id,
                selectedBillAddress: { id: billingAddress.id, address: this.convertToAddress(billingAddress) },
                selectedAddress: { id: shippingAddress.id, address: this.convertToAddress(shippingAddress) },
              },
              () => {
                if (!updateAddress) {
                  this.getShippingMethod();
                } else {
                  if (this.state.mode == "addShip") {
                    this.getShippingMethod();
                  }
                  this.setState({ loading: false, mode: "" });
                }
              }
            );
          } else {
            this.setState({ noAddress: true });
            this.onOpenModal();
          }
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  getDownlineList() {
    DOWNLINE.getDownlineList({ per_page: 100, page: 1, search: this.state.input })
      .then((res) => {
        let list = [];
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((member) => {
            list.push({ value: member.id, label: member.name + " - " + member.email });
          });
          this.setState({ memberList: res.data.data, options: list });
        } else {
          // this.setState({})
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  getDownlineAddress(id) {
    DOWNLINE.getDownlineAddressList(id)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.setState({
            memberAddressList: res.data,
          });
          this.changeUserAddress(res.data);
        } else {
          this.setState({
            memberAddressList: [],
          });
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  changeUserAddress(list) {
    let addressArr = [];
    let billingAddress = list[0];
    let shippingAddress = list[0];
    addressArr.push({ id: list[0].id, address: this.convertToAddress(list[0]) });
    this.setState(
      {
        addressArr: addressArr,
        defaultShip: shippingAddress,
        defaultBill: billingAddress,
        selectedBillID: billingAddress.id,
        selectedBillAddress: { id: billingAddress.id, address: this.convertToAddress(billingAddress) },
        selectedAddress: { id: shippingAddress.id, address: this.convertToAddress(shippingAddress) },
      },
      () => this.getShippingMethod()
    );
  }

  clearAddress() {
    this.setState({
      title: "0",
      fname: "",
      lname: "",
      mobileno: "",
      email: "",
      country: "",
      street1: "",
      street2: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      failMsg: [],
    });
  }

  renderErrorMsg(name) {
    if (this.state.failMsg && this.state.failMsg.find((msg) => msg.name == name) !== undefined) return this.state.failMsg.find((msg) => msg.name == name).msg;
    else return null;
  }

  async handleCheckOut() {
    const { cartItems, token } = this.props;
    const { selectedRole, remarks, shippingFee, selectedOption, selectedMethod, defaultShip, defaultBill, itemPack, shippingMethodID, packingFee, selectedDate, selectedTimeSlot, partnerServiceCharge, selectedOutStockOption } = this.state;

    let total = selectedRole == 2 || selectedRole == 3 ? this.getTotalByBuyer() : this.props.total;

    let data = this.getCheckoutFormData();
    data.append("remark", remarks);
    data.append("shipping_date", selectedDate);
    data.append("shipping_time_slot", selectedTimeSlot);
    data.append("outstock_option", selectedOutStockOption);

    let valid = true;
    data.append("shipping_method", shippingMethodID);
    if (itemPack) {
      data.append("packing", itemPack);
    }
    if (selectedRole == 2) {
      data.append("buyer_id", selectedOption.value);
    }
    if (selectedRole !== 3) {
      data.append("shipping_address_id", defaultShip.id);
      data.append("billing_address_id", defaultBill.id);
    }
    if (selectedMethod !== "bypass") data.append("payment_method", selectedMethod);
    data.append("shipping_fee", shippingFee);
    data.append("total_price", (Math.round((parseFloat(total) + (packingFee ? parseFloat(packingFee) : 0) + parseFloat(shippingFee) + (partnerServiceCharge ? parseFloat(partnerServiceCharge) : 0)) * 100) / 100).toFixed(2));
    for (let i = 0; i < cartItems.length; i++) {
      data.append("item[" + i + "][cart_id]", cartItems[i].cid);
      data.append("item[" + i + "][pid]", cartItems[i].pid);
      data.append("item[" + i + "][qty]", cartItems[i].qty);
      data.append("item[" + i + "][pov_id]", cartItems[i].selected_pov ? cartItems[i].selected_pov.id : null);
    }
    let referral = localStorage.getItem('referral');
    if (!!referral) {
      let referralData = JSON.parse(referral);
      data.append('ref', referralData.code);
    }
    this.setState({
      createAccErr: false
    })

    if (this.state.selectedOutStockOption <= 0) {
      valid = false;
      this.setState({ outStockOptionError: true });
    }

    if (this.state.createAcc && (!this.state.registerConfirmPassword || !this.state.registerPassword)) {
      valid = false;
      this.state.registerErrorMsg = 'Password and Confirm Password are required';
    } else {
      if (this.state.createAcc && (this.state.registerConfirmPassword != this.state.registerPassword)) {
        valid = false;
        this.state.registerErrorMsg = 'Password and Confirm Password are not match';
      }
    }

    if (valid && this.state.selectedRole === 4 && this.state.createAcc === true) {
      let createAccData = {
        email: this.state.buyerEmail,
        fname: this.state.accName,
        mobile_no: this.state.accMobile,
        password: this.state.registerPassword,
        sec_password: this.state.registerConfirmPassword,
        auto_signup: 1
      };
      await AUTH.register(createAccData).then(res => {
        this.setState({
          createAccErr: false
        })
      }).catch(err => {
        this.setState({
          createAccErr: true
        })
        if (err.data.email) {
          toast.error("Make payment failed, " + this.state.buyerEmail + " already registered", { autoClose: 4000, position: 'top-center' });
        } else if (err.data.password) {
          this.setState({ registerErrorMsg: err.data.password })
        } else if (err.data.sec_password) {
          this.setState({ registerErrorMsg: err.data.sec_password })
        } else {
          toast.error(err.message, { autoClose: 4000, position: 'top-center' });
        }
      })
    }

    if (valid && this.state.createAccErr === false) {
      ORDER.placeOrder(data)
        .then((res) => {
          if (selectedMethod == "bypass") {
            this.props.addMyCart([]);
            // this.props.history.replace({ pathname: "/checkout/order-success", state: { orderID: res.data.refno } });
          } else {
            if (this.state.payMethod.find((method) => method.id == selectedMethod).name == "Kiple Pay") {
              this.setState(
                {
                  paymentURL: res.data.payment_redirect.url,
                  postData: res.data.payment_redirect.data,
                },
                () => {
                  this.submitKipleBtn.click();
                }
              );
            } else if (this.state.payMethod.find((method) => method.id == selectedMethod).name == "IPay88") {
              this.setState(
                {
                  paymentURL: res.data.payment_redirect.url,
                  postData: res.data.payment_redirect.data,
                },
                () => {
                  this.submitIPayBtn.click();
                }
              );
            } else if (this.state.payMethod.find((method) => method.id == selectedMethod).name == "Revenue Monster") {
              this.setState(
                {
                  paymentURL: res.data.payment_redirect.url,
                  checkoutId: res.data.payment_redirect.checkoutId,
                },
                () => {
                  this.submitRMBtn.click();
                }
              );
            } else if (this.state.payMethod.find((method) => method.id == selectedMethod).name == "PayMaster UPP") {
              window.location = res.data.payment_redirect.url;
            } else {
              this.props.addMyCart([]);
              if (token) {
                this.props.history.replace({ pathname: "/checkout/order-success", state: { orderID: res.data.refno, orderDate: res.data.order_date, submitUrl: res.data.submit_url, submitMessage: res.data.submit_message } });
              } else {
                this.props.history.replace({
                  pathname: "/checkout/order-success",
                  state: { orderID: res.data.refno, order: res.data.order_details, isGuest: 1, submitUrl: res.data.submit_url, submitMessage: res.data.submit_message, orderDate: res.data.order_date },
                });
              }
            }
          }
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.push("/login");
          } else if (err.http_code == 400) {
            if (err.data) {
              if (err.data.item) {
                toast.error(err.data.item, { autoClose: 3000, position: "top-center" });
                this.props.history.replace("/cart");
              } else if (err.data.pov_id_inactive) {
                toast.error(err.data.pov_id_inactive + " Please go back your cart and refresh.", { autoClose: 3000, position: "top-center" });
              } else if (err.data.pov_id) {
                toast.error(err.data.item, { autoClose: 3000, position: "top-center" });
                this.props.history.replace("/cart");
              } else if (err.data.pid) {
                toast.error(err.data.pid, { autoClose: 3000, position: "top-center" });
                this.props.history.replace("/cart");
              } else if (err.data.qty) {
                toast.error(err.data.qty, { autoClose: 3000, position: "top-center" });
                this.props.history.replace("/cart");
              } else if (err.data.total_price) {
                toast.error("Some item price has updated, please try again", { autoClose: 3000, position: "top-center" });
                this.props.history.push("/cart");
              } else if (err.data.shipping_address) {
                toast.error(err.data.shipping_address, { autoClose: 3000, position: "top-center" });
              } else if (err.data.buyer_email) {
                toast.error(err.data.buyer_email, { autoClose: 3000, position: "top-center" });
              } else if (err.data.buyer_id) {
                toast.error(err.data.buyer_id, { autoClose: 3000, position: "top-center" });
              } else if (err.data.payment_method) {
                toast.error(err.data.payment_method, { autoClose: 3000, position: "top-center" });
              } else if (err.data.shipping_address_id) {
                toast.error(err.data.shipping_address_id, { autoClose: 3000, position: "top-center" });
              } else if (err.data.billing_address_id) {
                toast.error(err.data.billing_address_id, { autoClose: 3000, position: "top-center" });
              } else if (err.data.shipping_method) {
                toast.error(err.data.shipping_method, { autoClose: 3000, position: "top-center" });
              } else if (err.data.shipping) {
                toast.error(err.data.shipping, { autoClose: 3000, position: "top-center" });
              } else if (err.data.ewallet) {
                toast.error(err.data.ewallet, { autoClose: 3000, position: "top-center" });
              } else if (err.data.shipping_time) {
                toast.error(err.data.shipping_time, { autoClose: 3000, position: "top-center" });
              } else {
                toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
              }
            }

          } else {
            if (err.data) {
              if (err.data.shipping_address) toast.error(err.data.shipping_address, { autoClose: 3000, position: "top-center" });
              else {
                if (err.data.total_price) {
                  toast.error("Some item price has updated, please try again", { autoClose: 3000, position: "top-center" });
                  this.props.history.push("/cart");
                } else {
                  toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
                }
              }
            } else {
              toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
  }

  getDownlinePrice(id) {
    const { cartItems } = this.props;
    let data = new FormData();
    data.append("buyer_id", id);
    for (let i = 0; i < cartItems.length; i++) {
      data.append("item[" + i + "][cart_id]", cartItems[i].cid);
      data.append("item[" + i + "][pid]", cartItems[i].pid);
      data.append("item[" + i + "][qty]", cartItems[i].qty);
      data.append("item[" + i + "][pov_id]", cartItems[i].selected_pov ? cartItems[i].selected_pov.id : null);
    }
    DOWNLINE.getDownlinePrice(data)
      .then((res) => {
        this.setState({
          downlineCartItems: res.data,
        });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  handleUserChange = (selectedOption) => {
    this.setState({ selectedOption });
    if (selectedOption.value !== "0") {
      this.getDownlineAddress(selectedOption.value);
      this.getDownlinePrice(selectedOption.value);
    }
  };

  changeShippingMethod = (method, index) => {
    this.setState({ shippingMethodID: method, shippingFee: this.state.shippingMethodList[index].info.cost });
  };

  getShippingMethod() {
    const { shippingFee, partner } = this.state;

    let data = this.getCheckoutFormData();

    ORDER.getEligibleShippingMethod(data)
      .then((res) => {
        if (res.data.delivery_hub) {
          this.setState({
            deliveryHub: res.data.delivery_hub
          });
          if (res.data.delivery_hub[0]) {
            this.handleDeliveryHubChange(res.data.delivery_hub[0].id);
            var shippingList = res.data.delivery_hub[0].shipping.methods;
          }
          delete res.data.delivery_hub;
        } else {
          var shippingList = Object.values(res.data);
        }

        this.setState({
          shippingMethodList: shippingList,
          shippingMethodID: shippingList && shippingList[0] ? shippingList[0].shipping_method.id : 1,
          shippingFee: shippingList.length ? shippingList[0].info.cost : shippingFee,
          loading: false,
        });

        if (shippingList.length < 1) {
          toast.error("Shipment is not available for your location.", { autoClose: 10000, position: "top-center" });
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          this.tokenExpiredHandle();
        } else {
          if (err.http_code == 400) {
            if (err.data) {
              toast.error("Some item may out of stock", { autoClose: 3000, position: "top-center" });
            }
            this.props.history.replace("/cart");
          }
        }
      });
  }

  getCheckoutFormData() {
    const { cartInfo, cartItems } = this.props;
    const { selectedRole, defaultShip, selectedOption, oneTimeAddress, buyerEmail, partner, selectedDeliveryHub } = this.state;

    let data = new FormData();
    for (let i = 0; i < cartItems.length; i++) {
      data.append("item[" + i + "][cart_id]", cartItems[i].cid);
      data.append("item[" + i + "][pid]", cartItems[i].pid);
      data.append("item[" + i + "][qty]", cartItems[i].qty);
      data.append("item[" + i + "][pov_id]", cartItems[i].selected_pov ? cartItems[i].selected_pov.id : null);
    }

    if (partner) {
      data.append("partner_ref", partner);
    }

    if (selectedDeliveryHub) {
      data.append("delivery_hub", selectedDeliveryHub);
    }

    if (selectedRole == 1) {
      data.append("shipping_address_id", defaultShip.id);
    } else if (selectedRole == 2) {
      data.append("shipping_address_id", defaultShip.id);
      if (selectedOption) data.append("buyer_id", selectedOption.value);
    } else {
      if (cartInfo.guestCheckout) {
        if (cartInfo.requiredEmail) data.append("buyer_email", buyerEmail);
        data.append("guest_checkout", 1);
      } else {
        data.append("one_time_customer", 1);
      }
      let type = ["shipping", "billing"];
      for (let i = 0; i < type.length; i++) {
        data.append(type[i] + "_fname", oneTimeAddress[`${type[i]}_fname`]);
        if (oneTimeAddress[`${type[i]}_title`] !== "0") data.append(type[i] + "_title", oneTimeAddress[`${type[i]}_title`]);
        data.append(type[i] + "_street1", oneTimeAddress[`${type[i]}_street1`]);
        data.append(type[i] + "_street2", oneTimeAddress[`${type[i]}_street2`]);
        data.append(type[i] + "_city", oneTimeAddress[`${type[i]}_city`]);
        data.append(type[i] + "_zip", oneTimeAddress[`${type[i]}_zip`]);
        data.append(type[i] + "_state", oneTimeAddress[`${type[i]}_state`]);
        data.append(type[i] + "_mobileno", oneTimeAddress[`${type[i]}_mobileno`]);
        data.append(type[i] + "_country", "MY");
      }
    }
    return data;
  }

  changeItemPacking = (method) => {
    this.setState({ itemPack: method }, () => {
      this.getShippingFee();
    });
  };

  getShippingFee() {
    // let data = this.getCheckoutFormData();
    // data.append("shipping_method", this.state.shippingMethodID);
    // data.append("packing", this.state.itemPack);

    // ORDER.calculateShippingFee(data).then((res) => {
    //   if (res.data) {
    //     this.setState({ packingFee: res.data.packing, shippingFee: res.data.shipping });
    //   }
    // });
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    if (this.state.mode == "addBill" || this.state.mode == "addShip") {
      this.setState({ open: false });
      this.clearAddress();
    } else this.props.history.push("/cart");
  };

  convertToAddress(billingAddress) {
    return (
      billingAddress.street1 +
      (billingAddress.street2 && billingAddress.street2.length > 0 ? ", " + billingAddress.street2 : "") +
      ", " +
      billingAddress.zip +
      ", " +
      billingAddress.city +
      ", " +
      this.getState(billingAddress.state) +
      ", " +
      "Malaysia."
    );
  }

  validationChecking() {
    let failMsg = [];
    let valid = true;

    // checking for one time customer & guest form
    if (this.state.selectedRole == 3 || this.state.selectedRole == 4) {
      let type = this.state.useSameAddress ? ["shipping"] : ["shipping", "billing"];
      for (let i = 0; i < type.length; i++) {
        if (document.getElementById(type[i] + "_state").value == "") {
          valid = false;
          failMsg.push({ name: type[i] + "_state", msg: "State is required" });
        }
        if (!re.test(document.getElementById(type[i] + "_mobileno").value)) {
          valid = false;
          failMsg.push({ name: type[i] + "_mobileno", msg: "The Mobile Number is invalid" });
        }
        document.getElementById(type[i] + "_zip").value = document.getElementById(type[i] + "_zip").value.trimEnd();
        if (!reZip.test(document.getElementById(type[i] + "_zip").value)) {
          valid = false;
          failMsg.push({ name: type[i] + "_zip", msg: "This zip code is invalid, it may only contain 5 digit number" });
        }
      }
    } else {
      if (this.state.state == "") {
        valid = false;
        failMsg.push({ name: "state", msg: "State is required" });
      }
      if (!re.test(this.state.mobileno)) {
        valid = false;
        failMsg.push({ name: "mobileno", msg: "The Mobile Number is invalid" });
      }
      this.state.zip = this.state.zip.trimEnd()
      if (!reZip.test(this.state.zip)) {
        valid = false;
        failMsg.push({ name: "zip", msg: "This zip code is invalid, it may only contain 5 digit number" });
      }
    }

    this.setState({
      failMsg: failMsg,
    });
    return valid;
  }

  handleSubmit(e) {
    this.setState({
      failMsg: [],
    });
    e.preventDefault();
    let valid = this.validationChecking();
    if (valid === true) {
      const addressData = new FormData();
      if (this.state.title !== "0") addressData.append("title", this.state.title);
      addressData.append("fname", this.state.fname);
      addressData.append("shipping", this.state.shipping);
      addressData.append("billing", this.state.billing);
      addressData.append("street1", this.state.street1);
      addressData.append("street2", this.state.street2);
      addressData.append("city", this.state.city);
      addressData.append("zip", this.state.zip);
      addressData.append("state", this.state.state);
      addressData.append("mobileno", this.state.mobileno);
      addressData.append("country", "MY");

      PROFILE.addAddress(addressData)
        .then((res) => {
          toast.success("New address created successfully!", { autoClose: 3000, position: "top-center" });
          this.setState(
            {
              open: false,
              title: "0",
              fname: "",
              lname: "",
              mobileno: "",
              email: "",
              country: "",
              street1: "",
              street2: "",
              address: "",
              city: "",
              state: "",
              zip: "",
              billing: 0,
              shipping: 0,
              errShip: "",
              newAddressID: res.data.id,
            },
            () => {
              this.checkAddress();
            }
          );
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.push("/login");
          } else {
            this.checkOutOfStockErr(err);

            if (err.data) {
              if (err.data.shipping_address) toast.error(err.data.shipping_address, { autoClose: 3000, position: "top-center" });
              else {
                toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
              }
            } else {
              toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
  }

  setStateFromInput = (event, type) => {
    var obj = {};
    let addr = this.state.addressArr.find((add) => add.id == event.target.value).address;
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    if (type == "ship") {
      this.setState(
        {
          defaultShip: this.state.addressList.find((add) => add.id == event.target.value),
        },
        () => this.getShippingMethod()
      );
    } else {
      this.setState({
        defaultBill: this.state.addressList.find((add) => add.id == event.target.value),
      });
    }
  };

  handleChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);
  };

  checkhandle(value) {
    this.setState({
      selectedMethod: value,
    });
  }

  handleAdd(type) {
    if (type == "billing") {
      this.setState({
        mode: "addBill",
        open: true,
      });
    } else {
      this.setState({
        mode: "addShip",
        open: true,
      });
    }
  }

  renderAddressModal() {
    const { open, noAddress } = this.state;
    return (
      <Modal closeOnOverlayClick={false} open={open} onClose={this.onCloseModal} center>
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content quick-view-modal">
            <div className="modal-body p-3 pl-4 pr-4">
              <div className="page-title">
                <h3 style={{ color: "#222" }}>Add New Address</h3>
              </div>
              <div className="welcome-msg">{noAddress ? <p>Please add a new address in order to proceed checkout</p> : <p>Please fill in the form to add a new address </p>}</div>
              <div className="box-account box-info">
                <div className="box-title" />
                <div className="box-content">
                  <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="form-row" style={{ marginTop: "15px" }}>
                      <div className="col-md-6">
                        <label for="title">Title</label>
                        <select required onChange={(e) => this.setState({ title: e.target.value })} value={this.state.title} className="form-control" id="title">
                          <option value="0">Select title (Optional)</option>
                          <option value="1">Mr</option>
                          <option value="2">Ms</option>
                          <option value="3">Mrs</option>
                          <option value="4">Madam</option>
                        </select>
                      </div>
                      <div className="col-md-6" style={{ marginBottom: 12 }}>
                        <label htmlFor="fname">Name</label>
                        <input onChange={(e) => this.setState({ fname: e.target.value })} value={this.state.fname} type="text" maxlength="100" className="form-control" id="fname" placeholder="Your name" required />
                      </div>
                    </div>
                    <div className="form-row" style={{ marginTop: "15px" }}>
                      <div className="col-md-6" style={{ marginBottom: 12 }}>
                        <label htmlFor="street1">Street 1</label>
                        <input
                          onChange={(e) => this.setState({ street1: e.target.value })}
                          value={this.state.street1}
                          type="text"
                          className="form-control"
                          id="street1"
                          placeholder="18, Jalan Example"
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="street2">Street 2</label>
                        <input onChange={(e) => this.setState({ street2: e.target.value })} value={this.state.street2} type="text" className="form-control" id="street2" placeholder="Optional" />
                      </div>
                    </div>
                    <div className="form-row" style={{ marginTop: "15px" }}>
                      <div className="col-md-6" style={{ marginBottom: 12 }}>
                        <label htmlFor="city">City</label>
                        <input onChange={(e) => this.setState({ city: e.target.value })} value={this.state.city} type="text" className="form-control" id="city" placeholder="Kepong" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="zip">Zip</label>
                        <input onChange={(e) => this.setState({ zip: e.target.value })} value={this.state.zip} type="text" className="form-control" id="zip" placeholder="52100" required />
                        {this.renderErrorMsg("zip") && this.state.zip.length > 0 ? <div className="text-danger small">{this.renderErrorMsg("zip")}</div> : null}
                      </div>
                    </div>
                    <div className="form-row" style={{ marginTop: "15px" }}>
                      <div className="col-md-6" style={{ marginBottom: 12 }}>
                        <label htmlFor="state">State</label>
                        <select onChange={(e) => this.setState({ state: e.target.value })} value={this.state.state} class="form-control" id="state">
                          {stateArr.map((state, index) => (
                            <option key={index} value={Object.keys(state)}>
                              {Object.values(state)}
                            </option>
                          ))}
                        </select>
                        {this.renderErrorMsg("state") && this.state.state == "" ? <div className="text-danger small">{this.renderErrorMsg("state")}</div> : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="mobileno">Mobile Number</label>
                        <input
                          onChange={(e) => this.setState({ mobileno: e.target.value })}
                          value={this.state.mobileno}
                          type="number"
                          className="form-control"
                          id="mobileno"
                          placeholder="01xxxxxxxxx"
                          required
                        />
                        {this.renderErrorMsg("mobileno") && this.state.mobileno.length > 0 ? <div className="text-danger small">{this.renderErrorMsg("mobileno")}</div> : null}
                      </div>
                    </div>
                    <div hidden={noAddress} className="form-row" style={{ marginTop: "15px" }}>
                      <div className="col-md-6" style={{ marginBottom: 12 }}>
                        <label for="shipping">Default Shipping Address:</label>
                        <select required onChange={(e) => this.setState({ shipping: e.target.value })} value={this.state.shipping} class="form-control" id="shipping">
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                      <div hidden={noAddress} className="col-md-6">
                        <label for="billing">Default Billing Address:</label>
                        <select required onChange={(e) => this.setState({ billing: e.target.value })} value={this.state.billing} className="form-control" id="billing">
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <button style={{ marginTop: "20px" }} type="submit" className="btn btn-solid">
                        Confirm
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  getTotalByBuyer() {
    const { selectedRole, downlineCartItems } = this.state;
    let list = selectedRole == 2 ? downlineCartItems : this.props.cartItems;
    var total = 0;
    for (var i = 0; i < list.length; i++) {
      total += parseFloat(selectedRole == 2 ? list[i].subtotal : list[i].normalPrice * list[i].qty);
    }
    return total;
  }

  getDownlineItemPrice(id) {
    const { downlineCartItems } = this.state;
    if (downlineCartItems && downlineCartItems.length > 0) {
      let cartItem = downlineCartItems.find((item) => item.cart_id == id);
      if (cartItem) return cartItem.subtotal;
      else return 0;
    } else {
      return 0;
    }
  }

  handleRoleChange(id) {
    this.setState({ selectedRole: id }, () => {
      if (id == 1) {
        this.changeUserAddress(this.state.addressList);
      } else if (id == 2) {
        if (this.state.memberAddressList.length > 0) this.changeUserAddress(this.state.memberAddressList);
      }
    });
  }

  renderAddressForm(title, type) {
    return (
      <div style={{ padding: "20px 40px 20px 40px " }}>
        <h4 style={{ fontWeight: "bold" }}>{title}</h4>
        <hr className="mt-3" />
        {this.state.availabilityErrMsg ?
          <div className="mb-3" style={{ border: "1px solid #d20c0c", backgroundColor: "#ffdbdb" }}>
            <p style={{ marginBottom: 0, padding: "10px", color: "#d20c0c" }}>{this.state.availabilityErrMsg}</p>
          </div>
          : null}
        <div className="row">
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="fname">Name</label>
            <input type="text" className="form-control" id={type + "_fname"} maxlength="100" placeholder="Your name" required />
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="mobileno">Mobile Number</label>
            <input type="number" className="form-control" id={type + "_mobileno"} placeholder="01xxxxxxxxx" required />
            {this.renderErrorMsg(type + "_mobileno") && document.getElementById(type + "_mobileno").value.length > 0 ? (
              <div className="text-danger small">{this.renderErrorMsg(type + "_mobileno")}</div>
            ) : null}
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="title">Title</label>
            <select required className="form-control" id={type + "_title"}>
              <option value="0">Select title (Optional)</option>
              <option value="1">Mr</option>
              <option value="2">Ms</option>
              <option value="3">Mrs</option>
              <option value="4">Madam</option>
            </select>
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="street1">Street 1</label>
            <input type="text" className="form-control" id={type + "_street1"} placeholder="18, Jalan Example" defaultValue={this.state.shippingAddress ? this.state.shippingAddress.shipping_street1 : null} required />
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="street2">Street 2</label>
            <input type="text" className="form-control" id={type + "_street2"} placeholder="Optional" defaultValue={this.state.shippingAddress ? this.state.shippingAddress.shipping_street2 : null} />
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="city">City</label>
            <input type="text" className="form-control" id={type + "_city"} placeholder="Kepong" required defaultValue={this.state.shippingAddress ? this.state.shippingAddress.shipping_city : null} />
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="zip">Zip</label>
            <input type="text" className="form-control" id={type + "_zip"} placeholder="52100" required defaultValue={this.state.shippingAddress ? this.state.shippingAddress.shipping_zip : null} />
            {this.renderErrorMsg(type + "_zip") && document.getElementById(type + "_zip").value.length > 0 ? <div className="text-danger small">{this.renderErrorMsg(type + "_zip")}</div> : null}
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <label htmlFor="state">State</label>
            {/* <input onChange={(e)=>this.setState({state : e.target.value})} value={this.state.state} type="text" className="form-control" id="state" placeholder="Kuala Lumpur" required /> */}
            <select className="form-control" id={type + "_state"} defaultValue={this.state.shippingAddress ? this.state.shippingAddress.shipping_state : null}>
              {stateArr.map((state, index) => (
                <option key={index} value={Object.keys(state)}>
                  {Object.values(state)}
                </option>
              ))}
            </select>
            {this.renderErrorMsg(type + "_state") && document.getElementById(type + "_state").value == "" ? <div className="text-danger small">{this.renderErrorMsg(type + "_state")}</div> : null}
          </div>
        </div>
      </div>
    );
  }

  changeAddressMode() {
    const { useSameAddress } = this.state;
    this.setState({ useSameAddress: !useSameAddress }, () => {
      window.scrollTo(0, this.oneTimeFormRef.current.offsetTop + 150);
    });
  }

  async submitOneTimeAddress(e) {
    const { cartInfo } = this.props;
    e.preventDefault();
    let valid = false;
    let formData = {};
    let type = ["shipping", "billing"];
    let takeField = type;



    for (let i = 0; i < type.length; i++) {
      // if use same address as ship & bill then just take shipping_xxx field
      takeField = this.state.useSameAddress ? type[0] : type[i];

      valid = this.validationChecking(takeField);

      formData[type[i] + "_fname"] = e.target[`${takeField}_fname`].value;
      formData[type[i] + "_title"] = e.target[`${takeField}_title`].value;
      formData[type[i] + "_street1"] = e.target[`${takeField}_street1`].value;
      formData[type[i] + "_street2"] = e.target[`${takeField}_street2`].value;
      formData[type[i] + "_city"] = e.target[`${takeField}_city`].value;
      formData[type[i] + "_zip"] = e.target[`${takeField}_zip`].value;
      formData[type[i] + "_state"] = e.target[`${takeField}_state`].value;
      formData[type[i] + "_mobileno"] = e.target[`${takeField}_mobileno`].value;
      formData[type[i] + "_country"] = "MY";
    }

    if (valid) {
      let emailData = {};
      emailData['email'] = e.target.buyer_email.value;
      PROFILE.checkEmail(emailData).then(result => {
      }).catch(err => {
        toast.error("Email already registered, please login to continue checkout.", { autoClose: 3000, position: "top-center" });
        this.props.history.replace("/login");
      });

      let addressData = {};
      addressData.shipping_street1 = e.target[`${takeField}_street1`].value;
      addressData.shipping_street2 = e.target[`${takeField}_street2`].value;
      addressData.shipping_city = e.target[`${takeField}_city`].value;
      addressData.shipping_zip = e.target[`${takeField}_zip`].value;
      addressData.shipping_state = e.target[`${takeField}_state`].value;
      addressData.shipping_country = "MY";
      let email = e.target.buyer_email.value;
      this.setState({
        accName: e.target[`${takeField}_fname`].value,
        accMobile: e.target[`${takeField}_mobileno`].value
      })
      GENERAL.checkShippingAddressAvailability(addressData).then(res => {
        addressData.availability = true;
        localStorage.setItem("shipping", JSON.stringify(addressData));
        this.setState({
          availabilityErrMsg: null,
          proceedCheckout: true
        },
          () => {
            if (this.state.proceedCheckout === true) {
              this.setState(
                {
                  oneTimeAddress: formData,
                  buyerEmail: cartInfo.requiredEmail ? email : "",
                },
                () => {
                  if (!this.state.token) {
                    this.getShippingMethod();
                  }
                  this.getShippingFee();
                  window.scrollTo(0, 100);
                }
              );
            }
          });
      }).catch(err => {
        this.setState({
          availabilityErrMsg: err.message
        })
      })
    } else {
      window.scrollTo(0, 100);
    }

  }

  renderOrderForBox() {
    const { isDealer } = this.props;
    const { selectedRole, options, selectedOption } = this.state;

    return isDealer ? (
      <div className="border-box pt-1 pl-4 pr-4 mb-2">
        {/* <div className="pt-1 "> */}
        <div className="mr-3 font-weight-bold">Order For :</div>
        <div>
          {purchaseByList.map((role) => {
            return (
              <li key={role.id} className="mr-2 c-pointer" onClick={() => this.handleRoleChange(role.id)}>
                <div className="c-pointer">
                  <input type="radio" className="radio-btn" id={"order_" + role.name} checked={selectedRole == role.id} value={role.id} readOnly />
                  <label className="pl-2 pr-2 c-pointer" htmlFor={role.name}>
                    {role.name}
                  </label>
                </div>
              </li>
            );
          })}
        </div>
        {selectedRole == 2 ? (
          <div className="mb-2">
            <Select
              value={selectedOption}
              styles={customStyles}
              placeholder="Select user (name/email)"
              // onInputChange={(v)=>this.setState({input:v},()=>this.getDownlineList())}
              onChange={this.handleUserChange}
              options={options}
            />
          </div>
        ) : null}
      </div>
    ) : null;
  }

  renderOneTimeForm() {
    const { useSameAddress, selectedRole } = this.state;
    const { cartInfo } = this.props;

    return (
      <div className="col-12 mb-2">
        {this.renderOrderForBox()}
        <form onSubmit={(e) => this.submitOneTimeAddress(e)}>
          {selectedRole == 4 && cartInfo.requiredEmail ? (
            <div className="border-box p-3 pb-4 mb-3">
              <div className="col-12">
                <h4>Email Address</h4>
                <input type="email" className="form-control" id={"buyer_email"} placeholder="abc@example.com" required />
              </div>
            </div>
          ) : null}
          <div className="border-box pt-4" ref={this.oneTimeFormRef}>
            {useSameAddress ? (
              this.renderAddressForm("Shipping & Billing Details", "shipping")
            ) : (
              <>
                {this.renderAddressForm("Shipping Details", "shipping")}
                {this.renderAddressForm("Billing Details", "billing")}
              </>
            )}
            <div className="text-right" style={{ marginRight: 40, marginBottom: 20 }}>
              <button type="submit" className="btn-solid btn medium">
                Continue to Checkout
              </button>
            </div>
          </div>
        </form>

        <h5 className="text-primary c-pointer mt-2" onClick={() => this.changeAddressMode()}>
          {useSameAddress ? "Bill to different address" : "Use same address for ship and bill"}
        </h5>
      </div>
    );
  }

  toggleChange = () => {
    this.setState({
      createAcc: !this.state.createAcc,
      createAccErr: false
    });
  }

  renderAddressBox() {
    const { defaultShip, defaultBill, errShip, selectedRole, memberAddressList, oneTimeAddress, buyerEmail, createAcc, accMobile, accName, deliveryHub, registerPassword, registerConfirmPassword } = this.state;
    return (
      <div className="col-lg-6 col-sm-12 col-xs-12 mb-2">
        {this.renderOrderForBox()}
        {selectedRole === 4 ?
          <>
            <div className="border-box" style={{ marginBottom: "35px" }}>
              <div style={{ padding: "30px" }}>
                <div className="checkout-title" style={{ marginBottom: 2 }}>
                  <div className="row" style={{ paddingLeft: 15 }}>
                    <h4 style={{ fontWeight: "bold" }}>Contact Information</h4>
                    <p style={{ marginLeft: "auto", marginBottom: 0, lineHeight: "2.1", fontSize: "12px" }}>Already have an account?  <Link to={`${process.env.PUBLIC_URL}/login`}>
                      Login Now
                    </Link></p>
                  </div>
                  <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-envelope" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {buyerEmail}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-user" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {accName}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-phone" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {accMobile}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-12">
                    <div style={{ paddingLeft: "45px" }}>
                      <label>
                        <input type="checkbox"
                          checked={createAcc}
                          onChange={this.toggleChange}
                          className="mr-2"
                        />
                        Create an account
                        <br />
                        <span style={{ fontSize: "10px" }}>By ticking this box, you agree to our&nbsp;
                          <Link target="_blank" to={`${process.env.PUBLIC_URL}/terms`}>
                            Terms & Conditions
                          </Link> and that you have read our&nbsp;
                          <Link target="_blank" to={`${process.env.PUBLIC_URL}/return-refund-policy`}>
                            Returns & Refunds Policy
                          </Link>
                          .</span>
                      </label>
                      {/* <input type="checkbox" checked={createAcc} onClick={this.handleCreateAcc()} className="custom-control-input" />
                      <label className="custom-control-label"
                        htmlFor="create-acc">Create an account?
                      </label> */}
                      <div className="form-row" style={{ paddingBottom: "8px" }}>
                        <div className="col-md-12">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            value={registerPassword}
                            onChange={(e) => {
                              this.setState({
                                registerPassword: e.target.value
                              })
                            }}
                            className="form-control"
                            id="password"
                            placeholder="At least 6 characters and contain Alphabets, Numbers and Symbols"
                          />
                        </div>
                      </div>
                      <div className="form-row" style={{ paddingBottom: "8px" }}>
                        <div className="col-md-12">
                          <label htmlFor="ConPassword">Confirm Password</label>
                          <input
                            type="password"
                            value={registerConfirmPassword}
                            onChange={(e) => {
                              this.setState({
                                registerConfirmPassword: e.target.value
                              })
                            }}
                            className="form-control"
                            id="confirmPass"
                            placeholder="Confirm your password"
                          />
                        </div>
                      </div>
                      {
                        this.state.registerErrorMsg ?
                          <div style={errMsg}>{this.state.registerErrorMsg}</div> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          : null}

        {this.renderDeliveryHubSelectionBox()}

        <div className="border-box">
          <div style={{ padding: "30px" }}>
            <div className="checkout-title" style={{ marginBottom: 2 }}>
              <div className="row" style={{ paddingLeft: 15 }}>
                <h4 style={{ fontWeight: "bold" }}>Billing Details</h4>
                {selectedRole == 1 ? <i style={addIcon} onClick={() => this.handleAdd("billing")} className="fa fa-plus text-theme" /> : null}
              </div>
              <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
            </div>
            {selectedRole == 3 || selectedRole == 4 ? (
              <>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-user" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.billing_title !== "0" ? titleList.find((title) => title.id == oneTimeAddress.billing_title).name : "" + " "} {oneTimeAddress.billing_fname}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-map-marker" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.billing_street1}, {oneTimeAddress.billing_street2 && oneTimeAddress.billing_street2.length > 0 ? oneTimeAddress.billing_street2 + "," : ""}{" "}
                    {oneTimeAddress.billing_zip + ", " + oneTimeAddress.billing_city}, {stateArr.find((st) => st[oneTimeAddress.billing_state] !== undefined)[oneTimeAddress.billing_state]}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-phone" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.billing_mobileno}
                  </div>
                </div>
              </>
            ) : selectedRole == 2 && memberAddressList.length == 0 ? (
              <div>
                <h5>No Address Found</h5>
              </div>
            ) : (
              <>
                <select style={selectOpt} name="selectedBillAddress" value={this.state.selectedBillAddress.id} onChange={(e) => this.setStateFromInput(e, "bill")}>
                  {this.state.addressArr.map((data, index) => {
                    return (
                      <option key={index} value={data.id}>
                        {data.address}
                      </option>
                    );
                  })}
                </select>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-user" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultBill.title + " " + defaultBill.fname}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-map-marker" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultBill.street1}, {defaultBill.street2 && defaultBill.street2.length > 0 ? defaultBill.street2 + "," : ""} {defaultBill.zip + ", " + defaultBill.city},{" "}
                    {stateArr.find((st) => st[defaultBill.state] !== undefined)[defaultBill.state]}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-phone" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultBill.mobile_no}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="border-box" style={{ marginTop: "35px", marginBottom: "35px" }}>
          <div style={{ padding: "30px" }}>
            <div className="checkout-title" style={{ marginBottom: 2 }}>
              <div className="row" style={{ paddingLeft: 15 }}>
                <h4 style={{ fontWeight: "bold" }}>Shipping Details</h4>
                {this.state.selectedRole == 1 ? <i style={addIcon} onClick={() => this.handleAdd("shipping")} className="fa fa-plus text-theme" /> : null}
              </div>
              <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
            </div>
            {selectedRole == 3 || selectedRole == 4 ? (
              <>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-user" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.shipping_title !== "0" ? titleList.find((title) => title.id == oneTimeAddress.shipping_title).name : "" + " "} {oneTimeAddress.shipping_fname}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-map-marker" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.shipping_street1}, {oneTimeAddress.shipping_street2 && oneTimeAddress.shipping_street2.length > 0 ? oneTimeAddress.shipping_street2 + "," : ""}{" "}
                    {oneTimeAddress.shipping_zip + ", " + oneTimeAddress.shipping_city}, {stateArr.find((st) => st[oneTimeAddress.shipping_state] !== undefined)[oneTimeAddress.shipping_state]}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-phone" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {oneTimeAddress.shipping_mobileno}
                  </div>
                </div>
              </>
            ) : selectedRole == 2 && memberAddressList.length == 0 ? (
              <div>
                <h5>No Address Found</h5>
              </div>
            ) : (
              <>
                <select style={selectOpt} name="selectedAddress" value={this.state.selectedAddress.id} onChange={(e) => this.setStateFromInput(e, "ship")}>
                  {this.state.addressArr.map((data, index) => {
                    return (
                      <option key={index} value={data.id}>
                        {data.address}
                      </option>
                    );
                  })}
                </select>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-user" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultShip.title + " " + defaultShip.fname}
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "8px" }}>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-map-marker" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultShip.street1}, {defaultShip.street2 && defaultShip.street2.length > 0 ? defaultShip.street2 + "," : ""} {defaultShip.zip + ", " + defaultShip.city},{" "}
                    {stateArr.find((st) => st[defaultShip.state] !== undefined)[defaultShip.state]}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-sm-1 col-2">
                    <i style={{ paddingLeft: "5px" }} className="fa fa-phone" />
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-10" style={{ paddingLeft: "8px" }}>
                    {defaultShip.mobile_no}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {this.renderTimeSlotSelectionBox()}
        {this.renderRemarksBox()}
      </div>
    );
  }

  renderRemarksBox() {
    return (
      <div className="border-box mt-3" style={{ padding: 30, marginBottom: 30 }}>
        <h5 style={{ fontWeight: "bold" }}>Remarks (Optional)</h5>
        <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
        <textarea
          className="form-control"
          onChange={(e) => this.setState({ remarks: e.target.value })}
          value={this.state.remarks}
          style={{ marginTop: 10 }}
          placeholder="Write your remarks here..."
          rows="5"
        />
      </div>
    );
  }

  renderOutStockOptionBox() {
    return (
      <div className={"col-12 pl-0 pr-0"}>
        <div className="border-box p-1">
          <h5 style={{ fontWeight: "bold" }}>If The Product Has Out Of Stock</h5>
          <select required onChange={(e) => this.handleOutStockOptionChange(e.target.value)} value={this.state.selectedOutStockOption} className="form-control" id="shipping_date_selection">
            <option key={0} value={0} disabled>Please Select</option>
            <option key={1} value={1}>Replace with similar product with different brand</option>
            <option key={2} value={2}>Refund</option>
            <option key={3} value={3}>Call me to further decide</option>
          </select>
          {this.state.outStockOptionError ? <div className="text-danger small">The field is required</div> : null}
        </div>
      </div>
    );
  }

  handleOutStockOptionChange(e) {
    this.setState({
      selectedOutStockOption: e,
    });
  }

  handleDeliveryDateChange(e) {
    this.setState({
      selectedDate: e,
      shippingTimeArr: this.state.shippingTimeList[e],
      selectedTimeSlot: this.state.shippingTimeList[e][0].slot_id,
    });
  }

  handleDeliveryTimeChange(e) {
    this.setState({
      selectedTimeSlot: e,
    });
  }

  renderTimeSlotSelectionBox() {
    if (this.state.hasShippingTimeSlot) {
      return (
        <div className="border-box mt-3" style={{ padding: 30, marginBottom: 30 }}>
          <h5 style={{ fontWeight: "bold" }}>Delivery Time</h5>
          <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
          <div className="row">
            <div className="col-12 col-sm-6">
              <select required onChange={(e) => this.handleDeliveryDateChange(e.target.value)} value={this.state.selectedDate} className="form-control" id="shipping_date_selection">
                {this.state.shippingDateArr.map((date, index) => (
                  <option key={index} value={index}>
                    {Object.values(date)}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-6">
              <select required onChange={(e) => this.handleDeliveryTimeChange(e.target.value)} value={this.state.selectedTimeSlot} className="form-control" id="shipping_time_selection">
                {this.state.shippingTimeArr.map((time, index) => (
                  <option key={index} value={time.slot_id}>
                    {time.start_time} - {time.end_time}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  handleDeliveryHubChange(selectedHub) {
    for (var hub of this.state.deliveryHub) {
      if (hub.id == selectedHub) {
        this.setState({
          partnerServiceCharge: hub.service_charge,
          partnerServiceChargeName: hub.service_charge_name,
          shippingMethodList: hub.shipping.methods,
          shippingMethodID: hub.shipping.methods && hub.shipping.methods[0] ? hub.shipping.methods[0].shipping_method.id : 1,
          shippingFee: hub.shipping.methods.length ? hub.shipping.methods[0].info.cost : 0,
          selectedDeliveryHub: selectedHub,
        });
      }
    }
  }

  renderDeliveryHubSelectionBox() {
    if (this.state.deliveryHub.length >= 1) {
      return (
        <div className="border-box mt-3" style={{ padding: 30, marginBottom: 30 }}>
          <h5 style={{ fontWeight: "bold" }}>Deliver to</h5>
          <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
          <div className="row">
            <div className="col-12">
              <select className="form-control" id="delivery_hub_selection" onChange={(e) => this.handleDeliveryHubChange(e.target.value)}>
                {this.state.deliveryHub.map((info, index) => (
                  <option key={index} value={info.id}>
                    {info.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12">
              <p className="pt-3 mb-0" style={{ fontSize: "10px", lineHeight: "1.3" }}>For any orders that were placed after the cut off time of 12:30 pm on a Saturday or on a working day before a Public Holiday,
                your order will be processed on the following working day and your item(s) will be scheduled for delivery on that working day.</p>
            </div>
          </div>
        </div>
      );
    }
  }

  renderGuestForm() {
    const { useSameAddress } = this.state;
    const { cartInfo } = this.props;
    return (
      <div className="col-lg-6 col-sm-12 col-xs-12 mb-2">
        <form onSubmit={(e) => this.submitOneTimeAddress(e)}>
          {cartInfo.requiredEmail ? (
            <div className="border-box p-3 mb-3">
              <div className="col-12">
                <label>Email</label>
                <input type="text" className="form-control" id={"buyer_email"} placeholder="abc@example.com" required />
              </div>
            </div>
          ) : null}

          <div className="border-box pt-4" ref={this.oneTimeFormRef}>
            {useSameAddress ? (
              this.renderAddressForm("Shipping & Billing Details", "shipping")
            ) : (
              <>
                {this.renderAddressForm("Shipping Details", "shipping")}
                {this.renderAddressForm("Billing Details", "billing")}
              </>
            )}
          </div>
          <h5 className="text-primary c-pointer mt-2" onClick={() => this.changeAddressMode()}>
            {useSameAddress ? "Bill to different address" : "Use same address for ship and bill"}
          </h5>
        </form>
      </div>
    );
  }

  render() {
    const { symbol, cartItems, cartInfo, token } = this.props;
    const {
      postData,
      payMethod,
      selectedMethod,
      loading,
      errShip,
      shippingFee,
      shipStatus,
      freeWhenOver,
      selectedRole,
      selectedOption,
      options,
      memberAddressList,
      downlineCartItems,
      checkoutId,
      shippingMethodID,
      itemPack,
      shippingMethodList,
      packingFee,
      oneTimeAddress,
      partnerServiceCharge,
      partnerServiceChargeName,
    } = this.state;
    // total price base on different buyer
    let total = (selectedRole == 2 && downlineCartItems.length > 0 && selectedOption && selectedOption.value !== "0") || selectedRole == 3 ? (total = this.getTotalByBuyer()) : this.props.total;

    return cartInfo.guestCheckout == 0 && !token ? (
      <Redirect to="/login" />
    ) : total > 0 ? (
      <div>
        <Breadcrumb title={"Checkout"} />
        <section className="section-b-space pt-5">
          <div className="container padding-cls">
            {loading ? (
              <div className="loading-cls mt-5 mb-5" />
            ) : (
              <div className="checkout-page">
                <div className="checkout-form">
                  <div className="checkout row">
                    {(selectedRole == 3 || selectedRole == 4) && !oneTimeAddress ? this.renderOneTimeForm() : this.renderAddressBox()}
                    {(selectedRole == 3 || selectedRole == 4) && !oneTimeAddress ? null : (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        <div className="checkout-details">
                          <div className="order-box mb-0">
                            <div className="title-box">
                              <div>
                                Product <span style={{ textAlign: "right" }}> Total</span>
                              </div>
                            </div>
                            <ul className="qty">
                              {cartItems.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <div style={prodName}>
                                      {item.name}
                                      {item.selected_pov ? " (" + item.selected_pov.attribute1 + (item.selected_pov.attribute2 ? "+ " + item.selected_pov.attribute2 : "") : null}
                                      {item.selected_pov ? ")" : null} × {item.qty}{" "}
                                    </div>
                                    {item.combo ? (
                                      <div className="combo-list">
                                        {item.combo.map((comboItem) => {
                                          // return <p key={comboItem.id}>- {comboItem.product_name} (RM {user.role!==0 ? comboItem.dealer_price : comboItem.price})</p>;
                                          return <p key={comboItem.id}>- {comboItem.product_name}</p>;
                                        })}
                                      </div>
                                    ) : null}
                                    <span style={priceStyle}>
                                      {symbol}{" "}
                                      {parseFloat(
                                        selectedRole == 2 && selectedOption && selectedOption.value !== "0" && this.getDownlineItemPrice(item.cid) !== 0
                                          ? this.getDownlineItemPrice(item.cid)
                                          : selectedRole == 3
                                            ? item.normalPrice * item.qty
                                            : item.sum
                                      ).toFixed(2)}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                            <ul className="sub-total">
                              <li>
                                Subtotal{" "}
                                <span className="count" style={{ textAlign: "right" }}>
                                  {symbol}
                                  {parseFloat(total).toFixed(2)}
                                </span>
                              </li>
                              <li>
                                Shipping Fee{" "}
                                {shipStatus == 1 && parseFloat(freeWhenOver) > 0 && parseFloat(total) > parseFloat(freeWhenOver) ? (
                                  <>
                                    <span className="count" style={{ textAlign: "right" }}>
                                      {symbol} {parseFloat(0).toFixed(2)}
                                    </span>
                                    <div style={{ fontSize: 12, color: "green", padding: 2 }}>
                                      * Free shipping fee when over {symbol} {parseFloat(freeWhenOver).toFixed(2)}
                                    </div>
                                  </>
                                ) : (
                                  <span className="count" style={{ textAlign: "right" }}>
                                    {symbol} {parseFloat(shippingFee).toFixed(2)}
                                  </span>
                                )}
                              </li>
                              <li hidden={partnerServiceCharge <= 0}>
                                {partnerServiceChargeName} {" "}
                                <span className="count" style={{ textAlign: "right" }}>
                                  {symbol}
                                  {parseFloat(partnerServiceCharge).toFixed(2)}
                                </span>
                              </li>
                              {itemPack == 1 && packingFee ? (
                                <li>
                                  Packing Fee{" "}
                                  <span className="count" style={{ textAlign: "right" }}>
                                    {symbol}
                                    {parseFloat(packingFee).toFixed(2)}
                                  </span>
                                </li>
                              ) : null}
                            </ul>
                            <ul className="total">
                              <li>
                                Total{" "}
                                {shipStatus == 1 && parseFloat(freeWhenOver) > 0 && parseFloat(total) > parseFloat(freeWhenOver) ? (
                                  <span className="count" style={{ textAlign: "right" }}>
                                    {symbol} {parseFloat(parseFloat(total) + (partnerServiceCharge ? parseFloat(partnerServiceCharge) : 0)).toFixed(2)}
                                  </span>
                                ) : (
                                  <span className="count" style={{ textAlign: "right" }}>
                                    {symbol} {(Math.round((parseFloat(total) + (packingFee ? parseFloat(packingFee) : 0) + parseFloat(shippingFee) + (partnerServiceCharge ? parseFloat(partnerServiceCharge) : 0)) * 100) / 100).toFixed(2)}
                                  </span>
                                )}
                              </li>
                            </ul>
                            <div className="row ml-0 mr-0">
                              <div className={"border-box col-12 pl-0 p-1"}>
                                <div className="mr-3 font-weight-bold">Shipping Method :</div>
                                {shippingMethodList.length > 0 ? (
                                  shippingMethodList.map((method, index) => {
                                    return (
                                      <li key={index} className="mr-2 c-pointer" onClick={() => this.changeShippingMethod(method.shipping_method.id, index)}>
                                        <div className="c-pointer">
                                          <input
                                            type="radio"
                                            className="radio-btn"
                                            id={"shipping_method" + index}
                                            checked={shippingMethodID == method.shipping_method.id}
                                            value={method.shipping_method.id}
                                            readOnly
                                          />
                                          <label className="pl-2 pr-2 c-pointer" htmlFor={method.shipping_method.name}>
                                            {method.shipping_method.name}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <span className="text-danger">No available shipping for your location</span>
                                )}
                              </div>
                              {/* <div className={"border-box col-12 pl-0 p-1"} style={{ marginTop: 20 }}>
                                  <div className="mr-3 font-weight-bold">Items Packing:</div>
                                  {itemPackingList.map((item) => {
                                    return (
                                      <li key={item.id} className="mr-2 c-pointer" onClick={() => this.changeItemPacking(item.id)}>
                                        <div className="c-pointer">
                                          <input type="radio" className="radio-btn" id={item.id} checked={itemPack == item.id} value={item.id} readOnly />
                                          <label className="pl-2 pr-2 c-pointer" htmlFor={item.name}>
                                            {item.name}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </div> */}
                              {selectedMethod !== "bypass" ? ( // if is bypass user then hide payment method list
                                <div className={"col-12 pl-0 pr-0"}>
                                  <div className="payment-box">
                                    <div className="border-box p-1 payment-options">
                                      <h5 className="mr-3 font-weight-bold">Payment Method:</h5>
                                      <ul style={{ display: "grid" }}>
                                        {payMethod.map((method) => {
                                          return (
                                            <li key={method.id} onClick={() => this.checkhandle(method.id)}>
                                              <div className="radio-option ">
                                                <input
                                                  type="radio"
                                                  name="payment-group"
                                                  id={method.id}
                                                  checked={selectedMethod == method.id}
                                                  value={method.id}
                                                  onChange={this.handleChange.bind(this)}
                                                />
                                                <label className="c-pointer" htmlFor={method.name}>
                                                  {method.title}
                                                </label>
                                              </div>
                                            </li>
                                          );
                                        })}
                                        {/* <li>
                                            <div className="radio-option ">
                                              <input
                                                type="radio"
                                                name="coming_soon_payment"
                                                disabled="disabled"
                                              />
                                              <label className="c-pointer" htmlFor="">
                                                *Coming Soon: IPay88 FPX, E-wallet (Boost, Touch N Go)
                                            </label>
                                            </div>
                                          </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.renderOutStockOptionBox()}
                            </div>
                          </div>
                          {shippingMethodList.length == 0 || (selectedRole == 2 && memberAddressList.length == 0 && (!selectedOption || selectedOption.value == "0")) ? null : total !== 0 &&
                            selectedMethod != 0 &&
                            !errShip ? (
                            <div className="text-right mt-4">
                              <button type="button" onClick={() => this.handleCheckOut()} className="btn-solid btn">
                                {token ? "Make Payment" : "Make Payment"}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {/* pass Kiple payment use */}
        <form method="post" action={this.state.paymentURL}>
          <input type="hidden" name="ord_mercID" value={postData.ord_mercID} />
          <input type="hidden" name="ord_date" value={postData.ord_date} />
          <input type="hidden" name="ord_totalamt" value={postData.ord_totalamt} />
          <input type="hidden" name="ord_gstamt" value={postData.ord_gstamt} />
          <input type="hidden" name="ord_shipname" value={postData.ord_shipname} />
          <input type="hidden" name="ord_shipcountry" value={postData.ord_shipcountry} />
          <input type="hidden" name="ord_mercref" value={postData.ord_mercref} />
          <input type="hidden" name="ord_telephone" value={postData.ord_telephone} />
          <input type="hidden" name="ord_email" value={postData.ord_email} />
          <input type="hidden" name="ord_delcharges" value={postData.ord_delcharges} />
          <input type="hidden" name="ord_svccharges" value={postData.ord_svccharges} />
          <input type="hidden" name="ord_customfield1" value={postData.ord_customfield1} />
          <input type="hidden" name="ord_customfield2" value={postData.ord_customfield2} />
          <input type="hidden" name="ord_returnURL" value={postData.ord_returnURL} />
          <input type="hidden" name="merchant_hashvalue" value={postData.merchant_hashvalue} />
          <input hidden ref={(btn) => (this.submitKipleBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* pass IPay payment use */}
        <form method="post" action={this.state.paymentURL}>
          <input type="hidden" name="MerchantCode" value={postData.MerchantCode} />
          <input type="hidden" name="PaymentId" value={postData.PaymentId} />
          <input type="hidden" name="RefNo" value={postData.RefNo} />
          <input type="hidden" name="Amount" value={postData.Amount} />
          <input type="hidden" name="Currency" value={postData.Currency} />
          <input type="hidden" name="ProdDesc" value={postData.ProdDesc} />
          <input type="hidden" name="UserName" value={postData.UserName} />
          <input type="hidden" name="UserEmail" value={postData.UserEmail} />
          <input type="hidden" name="UserContact" value={postData.UserContact} />
          <input type="hidden" name="Remark" value={postData.Remark} />
          <input type="hidden" name="Lang" value={postData.Lang} />
          <input type="hidden" name="SignatureType" value={postData.SignatureType} />
          <input type="hidden" name="Signature" value={postData.Signature} />
          <input type="hidden" name="ResponseURL" value={postData.ResponseURL} />
          <input type="hidden" name="BackendURL" value={postData.BackendURL} />
          <input hidden ref={(btn) => (this.submitIPayBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* pass RM payment use */}
        <form method="get" action={this.state.paymentURL}>
          <input type="hidden" name="checkoutId" value={checkoutId} />
          <input hidden ref={(btn) => (this.submitRMBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* add/edit address modal  */}
        {this.renderAddressModal()}
      </div>
    ) : (
      <Redirect to="/cart" />
    );
  }
}

const selectOpt = {
  WebkitAppearance: "none",
  MozAppearance: "none",
  appearance: "none",
  padding: "5px",
  marginTop: 10,
  marginBottom: 10,
};

const addIcon = {
  paddingTop: "5px",
  paddingRight: "10px",
  fontSize: "15px",
  position: "absolute",
  right: 35,
  cursor: "pointer",
};

const prodName = {
  width: "61%",
};

const priceStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  textAlign: "right",
};

const errMsg = {
  color: 'red',
  marginLeft: 6,
  paddingBottom: 5,
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart
    .filter((cartItem) => cartItem.buyable == true)
    .sort(function compare(a, b) {
      var dateA = new Date(a.cdate);
      var dateB = new Date(b.cdate);
      return dateB - dateA;
    }),
  token: state.auth.token,
  total: getCartTotal(state.cartList.cart),
  symbol: state.data.symbol,
  cartInfo: state.data.cartInfo,
  user: state.data.user,
  isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
});

const mapDispatchToProps = (dispatch) => ({
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  updateToken: (token) => dispatch(updateToken(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(checkOut);
