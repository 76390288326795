import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import * as GENERAL from '../../api/general';
import ReactHtmlParser from 'react-html-parser';
import Contact from '../../components/pages/contact';
import { Helmet } from "react-helmet";

class Dynamic extends Component {
    constructor (props) {
        super (props)
        this.state={
            content:{},
            loading:true,
            mobileView:false
        }
    }

    componentWillMount(){
        this.getContent()
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({mobileView: window.innerWidth <= 600});
      }

    componentDidUpdate(prevProps){
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
              loading:true,
              content: {}
            },()=>{
                this.getContent()
            })
          }
    }

    getContent(){
        GENERAL.getMenuContent(this.props.location.pathname.substring(1)).then(res=>{
            var websiteTitle = localStorage.getItem("website");
            this.setState({
                content:res.data,
                loading:false,
                seoTitle: res.data.title + " | " + websiteTitle,
                seoDesc: res.data.title + " at " + websiteTitle
            })
        })
    }

    render (){
        const {loading,content, seoTitle, seoDesc} = this.state
        var seoImage = process.env.BASE_URL + "/logo.png";
        return (
            !loading?
            <>
              <Helmet>
                <meta property="og:image" content={seoImage} data-react-helmet="true" />
                <meta property="og:title" itemprop="name" content={seoTitle} />
                <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
                <meta itemprop="description" itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

                <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
                <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
                <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

                <meta property="description" content={seoDesc} data-react-helmet="true" />
                <meta property="title" content={seoTitle} data-react-helmet="true" />
                <title>{ seoTitle }</title>
              </Helmet>
                <Breadcrumb title={content.pagename}/>
                <div className="container" style={{minHeight:'600px'}}>
                    {content.pagename === 'Contact Us'?
                        <Contact PROPS={this.props}></Contact>
                    :
                    <div className="pt-3">
                        {ReactHtmlParser(content.descr)}
                    </div>
                    }
                </div>
            </>
            :<div style={loadingBar} className="loading-cls"></div>
        )
    }
}

const loadingBar = {
    marginTop: '120px',
    marginBottom:'120px'
}

const contentStyle = {
    paddingLeft:80,
    paddingRight:80
}

export default Dynamic