import store from "../store";
import {saveUser,updateToken,addMyCart} from '../actions/index';
const axios = require("axios");

export function login(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/user/login", {
        email: data.email,
        password: data.password,
      },{
        headers: {
          Accept: "application/json"
        }
      })
      .then(response => {
        const { data } = response;
        updateToken(data.data.access_token)
        localStorage.setItem('token',data.data.access_token)
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export function register(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL+process.env.API_PREFIX+"/user/register", data,{
        headers: {
          Accept: "application/json"
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function forgetPassword(data) {
  let url=process.env.API_URL+process.env.API_PREFIX+"/user/forgot-pw"
  return new Promise((resolve, reject) => {
    axios
      .post(url,data,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function resetPassword(data) {
  let url=process.env.API_URL+process.env.API_PREFIX+"/user/reset-pw"

  return new Promise((resolve, reject) => {
    axios
      .post(url,data,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export function getAccessToken() {
  let tokenData =  localStorage.getItem('token')
  if (tokenData) {
    return tokenData
  } else {
    return null;
  }
}

export async function logout(props) {
  let url=process.env.API_URL+process.env.API_PREFIX+"/user/logout"
  let accessToken =  getAccessToken()
  return new Promise(async(resolve, reject) => {
    axios
      .post(url,null,{
        headers: {
          Accept: "application/json",
          'token': accessToken
        }
      })
      .then(response => {
        removeUserFromStorage()
        const { data } = response;
        if(props){

          props.addMyCart([])
          props.updateToken(null)
        }
        resolve(data);
      })
      .catch(error => {
        removeUserFromStorage()
        if(props){
          props.updateToken(null)
        }
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

function removeUserFromStorage(){
  localStorage.removeItem('token')
  store.dispatch(updateToken(null));
  store.dispatch(addMyCart([]));
  store.dispatch(saveUser({}));
}