import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss?v1";
import { createBrowserHistory } from "history";
import { saveUser } from "./actions";
import * as GENERAL from "./api/general";
import * as CART from "./api/cart";
import * as AUTH from "./api/auth";
import * as PROFILE from "./api/profile";
// Import custom components
import store from "./store";
import translations from "./constants/translations";

import Layout from "./components/app";
import Home from "./components/home";
import Cart from "./components/cart";
import checkOut from "./components/checkout/index";
import orderSuccess from "./components/checkout/success-page";
import PaymentCheckout from "./components/checkout/payment-response";
import ForgetPassword from "./components/pages/forget-password";
import ResetPassword from "./components/pages/reset-password";
import PageNotFound from "./components/pages/404";
import DynamicPage from "./components/pages/dynamic";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import CheckOrder from "./components/order/check-order";

//Product Pages
import ProductDetails from "./components/products/productDetails";
import ProductList from "./components/products/productList";
import ProductSpecialList from "./components/products/productSpecialList";

// Members Pages
import Dashboard from "./components/members/dashboard/dashboard";
import Account from "./components/members/account/account";
import Address from "./components/members/address/address";
import OrderList from "./components/members/order/order-list";
import OrderDetail from "./components/members/order/order-detail";
import ChangePassword from "./components/members/change-password/change-password";
import NewAddress from "./components/members/address/address-new";
import EditAddress from "./components/members/address/address-edit";
import Wallet from "./components/members/wallet/wallet";
// import ProductReview from "./components/members/order/review-product";
// Dealer Pages
import MemberList from "./components/members/downline/member-list";
import MemberDetails from "./components/members/downline/member-details";
import AddMember from "./components/members/downline/add-member";
import CommissionList from "./components/members/commission-list";

import Cookies from "universal-cookie";
import moment from "moment";

const cookies = new Cookies();
var uuid = require("uuid");
const hist = createBrowserHistory();
const bannerType = 1; //main banner

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      footerList: [],
      bannerImg: [],
    };
    if (cookies.get("vid") == undefined) {
      let vID = moment(new Date()).format("HH:mm:ss") + uuid.v4();
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      cookies.set("vid", vID, { path: "/", expires: expiryDate });
    }
    CART.getLatestCart();
  }

  componentWillMount() {
    GENERAL.getFooterMenu().then((res) => {
      this.setState({
        footerList: res.data,
      });
      GENERAL.getBannerType(bannerType)
        .then((result) => {
          if (store.getState().auth.token) {
            this.getUser();
          } else {
            this.setState({ isLoading: false });
          }
          this.setState({
            bannerImg: result.data ? result.data : [],
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    });
  }

  getUser() {
    PROFILE.getProfile()
      .then((res) => {
        store.dispatch(
          saveUser({
            id: res.data.id,
            name: res.data.fname + " " + res.data.lname,
            role: res.data.dealer ? res.data.dealer : 0,
          })
        );
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout();
        }
      });
  }

  render() {
    const { footerList, bannerImg, isLoading } = this.state;
    return (
      <Provider store={store}>
        <Router basename={"/"} history={hist}>
          <ScrollContext>
            <Layout history={hist} footerMenu={footerList} isLoading={isLoading}>
              <div id="content-wrapper" className="content-wrapper">
                {!isLoading ? (
                  <Switch>
                    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                    <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                    <Route path={`${process.env.PUBLIC_URL}/forget-password`} component={ForgetPassword} />
                    <Route path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword} />

                    {footerList && footerList.length > 0
                      ? footerList.map((menu) => {
                          return <Route key={menu.id} path={`${process.env.PUBLIC_URL}/` + menu.url} component={DynamicPage} />;
                        })
                      : null}

                    {/*Routes For Products */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={() => <Home bannerImg={bannerImg} history={hist} isLoading={isLoading} />}
                    />
                    <Route path={`${process.env.PUBLIC_URL}/product/:id`} component={ProductDetails} />
                    <Route path={`${process.env.PUBLIC_URL}/product-category/:id`} component={ProductList} />
                    <Route path={`${process.env.PUBLIC_URL}/product-search`} component={ProductList} />
                    <Route path={`${process.env.PUBLIC_URL}/product-list`} component={ProductSpecialList} />
                    <Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
                    <Route path={`${process.env.PUBLIC_URL}/checkout/order-success`} component={orderSuccess} />
                    <Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut} />
                    <Route path={`${process.env.PUBLIC_URL}/payment-checkout`} component={PaymentCheckout} />
                    <Route path={`${process.env.PUBLIC_URL}/order-detail`} component={CheckOrder} />
                    <Route path={`${process.env.PUBLIC_URL}/myhub`} component={() => <Home bannerImg={bannerImg} history={hist} isLoading={isLoading} referral="myhub" />} />

                    {/*Routes For Member Pages*/}
                    <MemberRoute
                      exact
                      path={[`${process.env.PUBLIC_URL}/members/dashboard`, `${process.env.PUBLIC_URL}/members`]}
                      component={Dashboard}
                    />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/account`} component={Account} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/address`} component={Address} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/order`} component={OrderList} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/order-detail/:id`} component={OrderDetail} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/edit-pw`} component={ChangePassword} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/address-new`} component={NewAddress} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/address-edit/:id`} component={EditAddress} />
                    <MemberRoute path={`${process.env.PUBLIC_URL}/members/wallet`} component={Wallet} />
                    {/* <MemberRoute path={`${process.env.PUBLIC_URL}/members/review-product/:id`} component={ProductReview} /> */}

                    {/*Routes For Dealer Pages*/}
                    <DealerRoute path={`${process.env.PUBLIC_URL}/members/member-list`} component={MemberList} />
                    <DealerRoute path={`${process.env.PUBLIC_URL}/members/add-member`} component={AddMember} />
                    <DealerRoute path={`${process.env.PUBLIC_URL}/members/member-details/:id`} component={MemberDetails} />
                    <DealerRoute path={`${process.env.PUBLIC_URL}/members/commission-list`} component={CommissionList} />
                    <Route component={PageNotFound} />
                  </Switch>
                ) : null}
              </div>
            </Layout>
          </ScrollContext>
        </Router>
      </Provider>
    );
  }
}

const MemberRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      store.getState().auth.token ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

const DealerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      store.getState().data.user.role && store.getState().data.user.role !== 0 ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/404-not-found", state: { from: props.location } }} />
      )
    }
  />
);

ReactDOM.render(<Root />, document.getElementById("root"));
