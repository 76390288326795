import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { addToCart, updateToken, addMyCart } from "../../../actions";
import ProductItem from "../../products/product-item";
import { convertToCartItem } from "../../../services/index";
class ProductListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            mode: props.mode,
            limit: props.productList.length,
            hasMoreItems: props.productList.length == props.pagination.total ? false : true,
            id: props.id ? props.id : 0,
            productList: props.productList ? props.productList : [],
            pagination:
                props.pagination && props.pagination.total
                    ? props.pagination
                    : { page: "1", total: props.productList.length, total_page: 1 },
        };
    }

    fetchMoreItems = () => {
        if (this.props.productList.length == this.state.pagination.total) {
            this.setState({ hasMoreItems: false }, () => {
                return;
            });
        } else {
            if (window.location.pathname == "/product-search" || window.location.pathname == "/product-list") this.props.fetchNext(this.props.page + 1);
            else this.props.fetchNext(this.state.id, this.props.page + 1);
        }
    };

    render() {
        const { productList, addToCart, loading, symbol, mode, history, page } = this.props;
        return (
            <div style={productSection} className="animated fadeIn">
                {productList && !loading ? (
                    <div className="product-wrapper-grid">
                        {productList.length > 0 ? (
                            <InfiniteScroll
                                dataLength={productList.length} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls" />}>
                                <div className="row custom-product-card-row">
                                    {productList.slice(0, productList.length).map((product, index) => (
                                        <div
                                            id={"product-card-" + product.slug}
                                            className={`${
                                                this.props.colSize === 6
                                                    ? "col-xl-6 col-md-3 col-sm-3 col-grid-box"
                                                    : "col-xl-" + this.props.colSize
                                            } col-lg-3 col-md-4 col-6 custom-product-card`}
                                            key={index}>
                                            <ProductItem
                                                page={page}
                                                product={product}
                                                symbol={symbol}
                                                colSize={this.props.colSize}
                                                mode={mode}
                                                history={history}
                                                onAddToCartClicked={(product, qty) =>
                                                    addToCart(convertToCartItem(product), qty ? qty : 1, this.props)
                                                }
                                                key={index}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        ) : (
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                    <h3>Sorry! Couldn't find the product you were looking for!!! </h3>
                                    <p>Please check if you have misspelt something or try searching with other words.</p>
                                    {/* <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link> */}
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}

const productSection = {
    width: "100%",
    alignItems: "center",
};

const mapStateToProps = (state, ownProps) => {
    return {
        symbol: state.data.symbol,
    };
};

export default connect(
    mapStateToProps,
    { addToCart, updateToken, addMyCart }
)(ProductListing);
