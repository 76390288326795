import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import * as AUTH from '../../api/auth';
import { Helmet } from "react-helmet";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state={
      successMsg:'',
      errorMsg:false,
      email:''
    }
  }

  submitEmail(event) {
    event.preventDefault();
    this.setState({successMsg:'',errorMsg:''})
    let data = {
      email: event.target.email.value,
    };
    AUTH.forgetPassword(data)
      .then((res) => {
        this.setState({
          successMsg:res.message,
          email:''
        })
      })
      .catch((err) => {
        if (err.data) {
          if (err.data.email){
            this.setState({
              errorMsg: err.data.email,
            });
          }
        }
      });
  }

  render() {
    const {successMsg,errorMsg} = this.state
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Forgot Password | " + websiteTitle;
    var seoDesc = "Forgot password at " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{ seoTitle }</title>
        </Helmet>
        <Breadcrumb title={"forget password"} />
        {/*Forget Password section*/}
        <section className="pwd-page section-b-space" >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2>Forget Your Password?</h2>
                <form className="theme-form" onSubmit={(e) => this.submitEmail(e)}>
                  <div className="form-row">
                    <div className="col-md-12">
                      <input value={this.state.email} type="email" className="form-control"
                      id="email" placeholder="Enter Your Email" required
                        onChange={(e)=>this.setState({email:e.target.value})} />
                    </div>
                    <button type="submit" className="btn btn-solid">
                      Submit
                    </button>
                  </div>
                </form>
                {errorMsg?
                  <p style={fail}>
                    {errorMsg}
                  </p>
                :null}
                {successMsg?
                <p style={success}>
                  {successMsg}
                </p>
                :null}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const fail={
  width:'auto',
  textAlign:'center',
  color:'red',
  paddingTop:10
}

const success={
  width:'auto',
  textAlign:'center',
  color:'green',
  paddingTop:10
}

const adjust={
  marginTop:-30
}

export default ForgetPassword;
