import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class SideMenu extends Component {

  render() {
      const { activeTab,isDealer } = this.props
    return (
        <div className="dashboard-left">
        <div className="collection-mobile-back">
          <span className="filter-back">
            <i className="fa fa-angle-left" aria-hidden="true" /> back
          </span>
        </div>
        <div className="block-content">
          <ul>
            <li className={activeTab=='dashboard'?"active":''}>
              <Link to={"/members/dashboard"}>{isDealer?'Dashboard':'Account Info'}</Link>
            </li>
            <li className={activeTab=='order'?"active":''}>
              <Link to={"/members/order"}>My Orders</Link>
            </li>
            {isDealer?<>
            {/* <li className={activeTab=='member'?"active":''} >
              <Link to={"/members/member-list"}>Customer List</Link>
            </li>
            <li className={activeTab=='commission'?"active":''}>
              <Link to={"/members/commission-list"}>My Commission</Link>
            </li> */}
            </>:null}
            <li className={activeTab=='profile'?"active":''}>
              <Link to={"/members/account"}>My Profile</Link>
            </li>
            <li className={activeTab=='address'?"active":''}>
              <Link to={"/members/address"}>Address Book</Link>
            </li>
            {/* <li className={activeTab=='credit'?"active":''}>
              <Link to={"/members/wallet"}>My Credit</Link>
            </li> */}
            <li className={activeTab=='password'?"active":''}>
              <Link to={"/members/edit-pw"}>Edit Password</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state,) => {
  return {
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
  };
};


export default connect(mapStateToProps,null)(SideMenu);
