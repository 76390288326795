import * as AUTH from "./auth";
const axios = require("axios");
let apiRejectRequest = null;

export async function getCategoryList() {
  let url = process.env.API_URL + process.env.API_PREFIX + "/categories";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getProductList(params) {
  // cancel previous ajax
  if (apiRejectRequest) {
    apiRejectRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  apiRejectRequest = axios.CancelToken.source();

  let url = process.env.API_URL + process.env.API_PREFIX + "/products?";
  if (params.status) url += "&status=" + params.status;
  if (params.include) url += "&include=" + params.include;
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;
  if (params.sort_by) url += "&sort_by=" + params.sort_by;
  if (params.sort_type) url += "&sort_type=" + params.sort_type;
  if (params.is_promotion) url += "&is_promotion=" + params.is_promotion;
  if (params.is_featured) url += "&is_featured=" + params.is_featured;
  if (params.is_latest) url += "&is_latest=" + params.is_latest;
  if (params.category) url += "&category=" + params.category;
  if (params.is_trending) url += "&is_trending=" + params.is_trending;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
        cancelToken: apiRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        } else {
          if (!axios.isCancel(error)) {
            reject(error.message)
          }
        }
      });
  });
}

export async function getProduct(id, ref = null) {
  let urlQuery = id;
  if (ref !== null) {
    urlQuery = urlQuery + "?ref=" + ref;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.API_URL + process.env.API_PREFIX + "/product/" + urlQuery, {
        headers: {
          Accept: "application/json",
          token: AUTH.getAccessToken(),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getCategoryProductList(params) {
  // cancel previous ajax
  if (apiRejectRequest) {
    apiRejectRequest.cancel();
  }

  // creates a new token for upcomming ajax (overwrite the previous one)
  apiRejectRequest = axios.CancelToken.source();

  let url = process.env.API_URL + process.env.API_PREFIX + "/products/category/" + params.id + "?";
  if (params.status) url += "&status=" + params.status;
  if (params.include) url += "&include=" + params.include;
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;
  if (params.sort_by) url += "&sort_by=" + params.sort_by;
  if (params.sort_type) url += "&sort_type=" + params.sort_type;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
        cancelToken: apiRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        } else {
          if (!axios.isCancel(error)) {
            reject(error.message)
          }
        }
      });
  });
}

export async function searchProduct(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/products_search?status=1&value=" + params.value;
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;
  if (params.sort_by) url += "&sort_by=" + params.sort_by;
  if (params.sort_type) url += "&sort_type=" + params.sort_type;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          token: AUTH.getAccessToken(),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getRelatedProduct(id) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/products_related/" + id;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getHomeCategoryList(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/categories/selected?";
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getNonFeatureTopCategoryList(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/categories/top-non-feature?";
  if (params.per_page) url += "per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getTrendingProducts(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/products_trending?";
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      });
  });
}

export async function getSelectedProductList(params) {
  // cancel previous ajax
  // if (apiRejectRequest) {
  //   apiRejectRequest.cancel();
  // }

  // creates a new token for upcomming ajax (overwrite the previous one)
  apiRejectRequest = axios.CancelToken.source();

  let url = process.env.API_URL + process.env.API_PREFIX + "/products?";
  if (params.status) url += "&status=" + params.status;
  if (params.include) url += "&include=" + params.include;
  if (params.per_page) url += "&per_page=" + params.per_page;
  if (params.page) url += "&page=" + params.page;
  if (params.sort_by) url += "&sort_by=" + params.sort_by;
  if (params.sort_type) url += "&sort_type=" + params.sort_type;
  if (params.is_promotion) url += "&is_promotion=" + params.is_promotion;
  if (params.is_featured) url += "&is_featured=" + params.is_featured;
  if (params.is_latest) url += "&is_latest=" + params.is_latest;
  if (params.category) url += "&category=" + params.category;
  if (params.is_trending) url += "&is_trending=" + params.is_trending;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        },
        cancelToken: apiRejectRequest.token
      })
      .then((response) => {
        const { data } = response;
        if (data.http_code == "200") resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        } else {
          if (!axios.isCancel(error)) {
            reject(error.message)
          }
        }
      });
  });
}

