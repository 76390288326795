import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { addToCart, updateToken, addMyCart } from "../../actions/index";
import ProductItem from "../products/product-item";
import * as PRODUCTS from "../../api/product";
import { convertToCartItem } from "../../services/index";
import loading from "../../assets/images/loader.gif";
import Carousel from 'react-grid-carousel'
import { Redirect } from "react-router-dom";

class SpecialProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      productList: [],
      tab: ["is_promotion", "is_featured", "is_latest"],
      currentTab: 0,
      currentPage: 1,
      moreLoading: false,
      noResult:false,
    };
  }

  componentWillMount() {
    this.getProductList();
  }
  // http://localhost/harimau-mart-api/api/v1/products?&status=1&per_page=12&page=1&sort_by=sold_qty&sort_type=desc
  getProductList() {
    let tab = this.state.currentTab;
    let params = {
      per_page: 24,
      page: this.state.currentPage,
      status: 1,
      sort_type: "desc",
      sort_by: "id"
    };
    if (tab == 0) params.sort_by = "sold_qty";
    else if (tab == 1) params.is_featured = 1;
    else params.is_latest = 1;
    PRODUCTS.getProductList(params)
      .then(res => {
        if(res.data){
          this.setState(
            {
              productList: this.state.currentPage == 1 ? res.data? res.data:[] : [...this.state.productList, ...res.data],
              totalPage: res.pagination.total_page
            },
            () => {
              this.setState({
                isLoading: false,
                moreLoading: false
              });
            }
          );
        }else{
          this.setState({
            noResult: true,
            isLoading: false,
            moreLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          moreLoading: false
        });
        console.log(err);
      });
  }

  fetchMore() {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
        moreLoading: true
      },
      () => {
        this.getProductList();
      }
    );
  }

  changeTab(tab) {
    if (tab !== this.state.currentTab) {
      this.setState({ currentTab: tab, currentPage: 1, productList: [] }, () => {
        this.getProductList();
      });
    }
  }

  viewMoreContent = () => {
    return (
      <>
        {this.state.moreLoading ? (
          <div className="container" style={{ textAlign: "center" }}>
            <img src={loading} style={loaderStyle} alt="" />
          </div>
        ) : this.state.currentPage !== this.state.totalPage ? (
          <div className="container text-theme" style={getMoreStyle} onClick={() => this.fetchMore()}>
            <b>
              view more <i className="fa fa-angle-double-down" aria-hidden="true" />
            </b>
          </div>
        ) : null}
      </>
    );
  };

  goViewMorePage = () => {
    if (this.state.currentTab == 0) {
      this.props.history.push(`/product-list?value=popular_item`)
    }
  }

  carouselLeft = () => {
    return (
      <>
        <span type="prev" className="carousel-before"></span>
      </>
    )
  }

  carouselRight = () => {
    return (
      <>
        <span type="next" className="carousel-after"></span>
      </>
    )
  }

  render() {
    const { productList,noResult } = this.state;
    const { addToCart, title, highlightTitle, subtitle, symbol,history } = this.props;
    const categoryResponsive =
    [
      {
        breakpoint: 1200,
        cols: 4,
        rows: 2,
        loop: true,
        autoplay: false
      },
      {
        breakpoint: 991,
        cols: 3,
        rows: 2,
        loop: true,
        autoplay: false
      },
      {
        breakpoint: 767,
        cols: 2,
        rows: 6,
        loop: false,
        autoplay: false,
        arrowLeft: false,
        arrowRight: false
      }
    ]
    return (
      <div className="custom-recommended" style={{minHeight:'300px',justifyContent:'center'}}>
        <div className="main-box container" style={{}}>
          <div className="title1" >
            {subtitle ? <h4>{subtitle}</h4> : ""}
            <h2 className="title-inner1" style={{margin:0}}>
              <span className="highlight">{highlightTitle}</span>
              {title}
            </h2>
          </div>
          {this.state.isLoading && productList ? (
            <div className="loading-cls" />
          ) : (
            <section className="section-b-space addtocart_count" style={{paddingTop:0}}>
              <div className="container">
                <Tabs className="theme-tab custom-tab-style" style={customTabStyle}>
                  <TabList className="tabs tab-title" style={{textAlign:'left'}}>
                    <Tab onClick={() => this.changeTab(0)}>Popular Item</Tab>
                    {/* <span> | </span>
                    <Tab onClick={() => this.changeTab(1)}>Featured Products</Tab>
                    <span> | </span>
                    <Tab onClick={() => this.changeTab(2)}>New Products</Tab> */}
                    {this.state.moreLoading ? (
                      <div className="container" style={{ textAlign: "center" }}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : this.state.currentPage !== this.state.totalPage ? (
                      <div className="text-theme" style={getMoreStyle} onClick={() => this.goViewMorePage()}>
                        <b>
                          View more <i className="fa fa-angle-double-right" aria-hidden="true" />
                        </b>
                      </div>
                    ) : null}
                    <hr style={{margin:'0'}}/>
                  </TabList>
                  <TabPanel>
                    <div className="slider row" style={{margin:'0 auto'}}>
                      
                      {productList.length == 0 ? (
                        noResult?
                        <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      : <div className="container" style={loadingContainer}>
                          <img src={loading} style={loaderStyle} alt="" />
                        </div>
                      ) : 
                      <Carousel arrowLeft={this.carouselLeft} arrowRight={this.carouselRight} mobileBreakpoint={0} responsiveLayout={categoryResponsive} cols={6} rows={2} loop autoplay={false}>
                        {productList.map((product, index) => { return (
                          <Carousel.Item scrollSnap={true}>
                            <div key={index} >
                              <ProductItem
                                product={product}
                                symbol={symbol}
                                history={history}
                                updateToken={updateToken}
                                onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                                key={index}
                              />
                            </div>
                          </Carousel.Item>
                        )})}
                      </Carousel>
                        // this.viewMoreContent()
                      }
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="no-slider row">
                      {productList.map((product, index) => (
                        <ProductItem
                          product={product}
                          symbol={symbol}
                          history={history}
                          updateToken={updateToken}
                          onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                          key={index}
                        />
                      ))}
                      {productList.length == 0 ? (
                        noResult?
                        <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      :
                        <div className="container" style={loadingContainer}>
                          <img src={loading} style={loaderStyle} alt="" />
                        </div>
                      ) : (
                        this.viewMoreContent()
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className=" no-slider row">
                      {productList.map((product, index) => (
                        <ProductItem
                          product={product}
                          updateToken={updateToken}
                          history={history}
                          symbol={symbol}
                          onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                          key={index}
                        />
                      ))}
                      {productList.length == 0 ? (
                        noResult?
                          <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                        :
                        <div className="container" style={loadingContainer}>
                          <img src={loading} style={loaderStyle} alt="" />
                        </div>
                      ) : (
                        this.viewMoreContent()
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </section>
          )}
        </div>
      </div>
    );
  }
}

const loadingContainer = {
  textAlign: "center",
  minHeight:'400px',
  justifyContent:'center'
}

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop:'200px'
};

const getMoreStyle = {
  fontSize: 16,
  cursor: "pointer",
  letterSpacing: "0px",
  position: "absolute",
  right: "0",
  fontWeight: "300",
  marginTop: "35px",
  marginRight: "30px",
  display: "inline-block",
};

const customTabStyle = {
  background: "#fff"
};

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps,{ addToCart, updateToken, addMyCart })(SpecialProducts);
