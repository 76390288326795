import React, { Component, Fragment } from "react";
import { toast } from 'react-toastify';
import * as AUTH from '../../api/auth'
import * as ORDER from '../../api/order'
import * as General from "../../api/general";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
const stateArr = General.getStateList();
const getBadge = (status) => {
    return status === "1"
        ? "badge badge-info" //pending
        : status === "2"
            ? "badge badge-secondary" //processing
            : status === "3"
                ? "badge badge-success" //delivered
                : status === "4"
                    ? "badge badge-success" //collected
                    : status === "5"
                        ? "badge badge-dark" //cancelled
                        : status === "6"
                            ? "badge badge-success" //refunded
                            : status === "7"
                                ? "badge badge-success" //payment cleared / received
                                : status === "8"
                                    ? "badge badge-warning" //payment clearing
                                    : status === "9"
                                        ? "badge badge-info" //deposit received
                                        : status === "10"
                                            ? "badge badge-info" //waiting stock
                                            : status === "11"
                                                ? "badge badge-secondary" //request cancelled
                                                : status === "12"
                                                    ? "badge badge-secondary" //request refund
                                                    : status === "13"
                                                        ? "badge badge-danger" //request refund rejected
                                                        : "badge badge-primary";
};
class CheckOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderNum: "",
            email: "",
            orderKey: "",
            failMsg: "",
            orderDetails: [],
            shippingDetails: [],
            billingDetails: [],
            paymentDetails: [],
            orderList: [],
            notFound: false,
            cancelSuccess: false,
            bankName: "",
            bankAccName: "",
            bankAccNo: "",
            openModal: false,
        }

    }

    async componentWillMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        let params = this.props.location.search
        params = new URLSearchParams(params);
        if (params.get('email')) {
            this.setState({
                email: params.get('email')
            })
        }
        if (params.get('order')) {
            this.setState({
                orderNum: params.get('order')
            })
        }
        if (params.get('key')) {
            this.setState({
                orderKey: params.get('key')
            })
        }

        this.getBankInfo();
    }

    resize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    getBankInfo() {
        General.getSystemSettingsGeneral().then((res) => {
            this.setState({
                bankName: res.data.bank_name.value,
                bankAccName: res.data.bank_account_name.value,
                bankAccNo: res.data.bank_account_no.value,
            });
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var order_num = event.target.orderNum.value;
        order_num = order_num.replace(/[a-zA-Z ]/g, "");
        let data = {
            order_num: order_num,
            email: event.target.email.value,
            key: this.state.orderKey
        }

        this.setState(
            {
                loading: true,
                orderDetails: [],
                shippingDetails: [],
                billingDetails: [],
                paymentDetails: [],
                orderList: [],
                refno: ''
            });

        this.showOrderDetail(data)
    }

    showOrderDetail(data) {
        ORDER.showOrderDetail(data)
            .then((res) => {
                this.setState(
                    {
                        orderDetails: res.data,
                        shippingDetails: res.data.shipping,
                        billingDetails: res.data.billing_info,
                        paymentDetails: res.data.payment,
                        orderList: res.data.order_detail,
                        refno: res.data.refno,
                        paySlipFile: '',
                        paySlipUrl: '',
                    },
                    () => {
                        this.setState({
                            loading: false,
                        });
                    }
                );
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                if (err.http_code == 404) {
                    toast.error("Order not found", { autoClose: 3000, position: "top-center" });
                } else if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
                    AUTH.logout(this.props);
                    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
                    this.props.history.replace("/login");
                } else {
                }
            });
    }

    cancelOrder() {
        var order_num = this.state.orderNum;
        order_num = order_num.replace(/[a-zA-Z ]/g, "");
        let data = {
            order_num: order_num,
            email: this.state.email,
            key: this.state.orderKey
        }

        ORDER.guestCancelOrder(data)
            .then((res) => {
                toast.success(res.data.order);
                this.setState({ cancelSuccess: true, openModal: false });
                this.showOrderDetail(data);
            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 3000, position: "top-center" });
            });
    }

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                paySlipFile: file,
                paySlipUrl: reader.result,
            });
        };
        if (file) reader.readAsDataURL(file);
    };

    handleFileSubmit(e) {
        e.preventDefault();
        const slipData = new FormData();
        slipData.append("inputImage", this.state.paySlipFile);
        slipData.append("email", this.state.email);

        ORDER.guestUploadPaySlip(slipData, this.state.refno)
            .then((res) => {
                toast.success("Update Bank Transfer Reference Successful!", { autoClose: 3000 });
                var order_num = this.state.orderNum;
                order_num = order_num.replace(/[a-zA-Z ]/g, "");
                let data = {
                    order_num: order_num,
                    email: this.state.email,
                    key: this.state.orderKey
                }
                this.showOrderDetail(data);
            })
            .catch((err) => {
                if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
                    AUTH.logout(this.props);
                    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
                    this.props.history.replace("/login");
                }
                toast.error(err.message);
            });
    }

    orderReceived() {
        var order_num = this.state.orderNum;
        order_num = order_num.replace(/[a-zA-Z ]/g, "");
        let data = {
            order_num: order_num,
            email: this.state.email,
            key: this.state.orderKey
        }

        ORDER.guestOrderReceived(data)
            .then((res) => {
                toast.success("Order Received");
                this.showOrderDetail(data);
            })
            .catch((err) => {
                toast.error(err.message, { autoClose: 3000, position: "top-center" });
            });
    }

    getState(state) {
        for (let i = 0; i < stateArr.length; i++) {
            if (state == Object.keys(stateArr[i]).toString()) return Object.values(stateArr[i]).toString();
        }
    }

    renderCancelConfirmation = () => {
        const { openModal } = this.state;
        return (
            <Modal open={openModal} closeOnOverlayClick={false} onClose={() => this.setState({ openModal: false })} center>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content p-3">
                        <h3 className="text-center" style={{ color: "black" }}>
                            Confirmation
                </h3>
                        <div className="modal-body text-center">
                            <p>Are you sure you want to cancel this order?</p>

                            <div className="mt-2 d-inline-flex">
                                <button
                                    className="btn btn-solid medium mr-2"
                                    onClick={() => {
                                        this.cancelOrder();
                                    }}>
                                    Confirm
                    </button>
                                <button className="btn btn-solid medium" onClick={() => this.setState({ openModal: false })}>
                                    Cancel
                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    printInvoice() {
        const method = "GET";
        const type = "application/pdf";
        const token = this.props.token;
        const headers = new Headers({ token, type });
        const options = { method, headers };

        var order_num = this.state.orderNum;
        order_num = order_num.replace(/[a-zA-Z ]/g, "");

        fetch(process.env.API_URL + process.env.API_PREFIX + "/export-order-invoice?order=" + order_num + "&email=" + this.state.email, options)
            .then((response) => response.blob())
            .then((response) => {
                var blob = new Blob([response], { type });
                const obj = URL.createObjectURL(blob);
                this.setState({ blobObject: obj });
                window.open(obj, "_blank");
            })
            .catch((err) => {
                if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
                    AUTH.logout(this.props);
                    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
                    this.props.history.replace("/login");
                }
            });
    }

    renderOrderInfo() {
        const { orderDetails, orderList } = this.state;
        return (
            <div style={orderBox}>
                {/* {orderDetails.buyer && orderDetails.buyer.fname && orderDetails.buyer.id!==user.id? (
          <p style={{ marginBottom: "0px" }}>
            Purchased By : {orderDetails.buyer.fname}</p>
        ) : null} */}
                <p className="text-theme" style={{ marginBottom: "0px", fontSize: "13px" }}>
                    Order #{orderDetails.refno}
                </p>
                <div className="form-row">
                    <div className="col-lg-6">
                        <p style={{ fontSize: "10px", marginBottom: "8px" }}>Placed on {orderDetails.cdate}</p>
                    </div>
                    <div className="col-lg-6">
                        <span
                            style={{ float: "right", color: "white", padding: "5px" }}
                            className={getBadge(orderDetails.status.id) + " mb-2"}>
                            {orderDetails.status.id == "1" ? "Pending Payment" : orderDetails.status.name}
                        </span>
                    </div>
                </div>
                <hr style={{ marginBottom: "3px", marginTop: "0px" }} />
                <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
                {orderList.map((order, index) => (
                    <Fragment key={index}>
                        <div className="order-list row">
                            <div className="col-lg-5 col-md-5 col-12">
                                <p>
                                    {order.pname}{" "}
                                    {order.combo ? (
                                        <div className="combo-list order">
                                            {order.combo.map((comboItem, index) => {
                                                return <p key={index}>- {comboItem}</p>;
                                            })}
                                        </div>
                                    ) : null}
                                    {order.attribute1 ? "(" + order.attribute1 + (order.attribute2 ? "+ " + order.attribute2 : "") : null}
                                    {order.attribute1 ? ")" : null}
                                </p>
                            </div>
                            {order.pprice === "0.00" || order.pprice === order.rprice ? (
                                <div className="col-lg-2 col-md-2 col-4">
                                    <p>RM {order.unit_price}</p>
                                </div>
                            ) : (
                                    <div className="col-lg-2 col-md-2 col-4">
                                        <p>
                                            <span>RM {order.pprice}</span>
                                            <br />
                                            <strike style={{ fontSize: 12, color: "#5f5f5f" }}>RM {order.rprice}</strike>
                                        </p>
                                    </div>
                                )}
                            <div className="col-lg-2 col-md-2 col-4">
                                <p>
                                    <span style={{ color: "#bfbfbf" }}>Qty:</span> {order.qty}
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-4">
                                <p>RM {order.sub_total}</p>
                            </div>
                        </div>
                        {/* {order.check_review == 0 && orderDetails.status.id == 4 ? (
                  <div className="text-right mb-2">
                    <Link
                      to={{
                        pathname: `${process.env.PUBLIC_URL}/members/review-product/${order.id}`,
                        state: { refno: orderDetails.refno, product: order },
                      }}>
                      <button className="btn btn-outline-info small">Review</button>
                    </Link>
                  </div>
                ) : null} */}
                        <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
                    </Fragment>
                ))}
                <br></br>
                <p>*If the product has out of stock:&nbsp;
                {orderDetails.outstock_option} </p>
            </div>
        );
    }

    renderAddressInfo() {
        const { shippingDetails, billingDetails } = this.state;
        return (
            <>
                <div className="col-lg-6">
                    <div style={orderBox}>
                        <h4 style={{ fontSize: "15px" }}>Shipping Address</h4>
                        <p style={addLbl}>
                            {shippingDetails.shipping_fname} {shippingDetails.shipping_lname}
                        </p>
                        <p style={addLbl}>{shippingDetails.shipping_mobile_no}</p>
                        <hr style={{ marginBottom: "5px", marginTop: "8px" }} />
                        <p style={addLbl}>
                            {shippingDetails.shipping_street1},{" "}
                            {shippingDetails.street2 && shippingDetails.street2.length > 0 ? shippingDetails.street2 + "," : ""}{" "}
                            {shippingDetails.shipping_city}, {shippingDetails.shipping_zip}, {this.getState(shippingDetails.shipping_state)}
                        </p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div style={orderBox}>
                        <h4 style={{ fontSize: "15px" }}>Billing Address</h4>
                        <p style={addLbl}>
                            {billingDetails.fname} {billingDetails.lname}
                        </p>
                        <p style={addLbl}>{billingDetails.mobile_no}</p>
                        <hr style={{ marginBottom: "5px", marginTop: "8px" }} />
                        <p style={addLbl}>
                            {billingDetails.street1},{" "}
                            {billingDetails.street2 && billingDetails.street2.length > 0 ? billingDetails.street2 + "," : ""}{" "}
                            {billingDetails.city}, {billingDetails.zip}, {this.getState(billingDetails.state)}
                        </p>
                    </div>
                </div>
            </>
        );
    }

    renderOrderSummary() {
        const { orderDetails, paymentDetails } = this.state;
        return (
            <div className="col-lg-6">
                <div style={orderBox}>
                    <h4 style={{ fontSize: "15px" }}>Total Summary</h4>
                    <div className="form-row">
                        <div className="col-lg-5">
                            <p style={addLbl}>Subtotal</p>
                        </div>
                        <div className="col-lg-7">
                            <p style={pymtPriceLbl}>RM {paymentDetails.subtotal}</p>
                        </div>
                    </div>
                    {paymentDetails.total_discount ? (
                        <div className="form-row">
                            <div className="col-lg-5">
                                <p style={addLbl}>Total Discount</p>
                            </div>
                            <div className="col-lg-7">
                                <p style={pymtPriceLbl}>RM {paymentDetails.total_discount}</p>
                            </div>
                        </div>
                    ) : ("")}
                    <div className="form-row">
                        <div className="col-lg-5">
                            <p style={addLbl}>Shipping Fees</p>
                        </div>
                        <div className="col-lg-7">
                            <p style={pymtPriceLbl}>RM {orderDetails.shipping_info.shipping_fee}</p>
                        </div>
                    </div>
                    <hr style={{ marginBottom: "15px", marginTop: "8px" }} />
                    <div className="form-row">
                        <div className="col-lg-5">
                            <p style={addLbl}>Total</p>
                        </div>
                        <div className="col-lg-7">
                            <p className="text-theme" style={totalPriceLbl}>
                                RM {paymentDetails.total_price}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { orderDetails, paymentDetails, paySlipUrl, cancelSuccess, bankAccName, bankAccNo, bankName, windowWidth } = this.state;

        return (
            <div>
                {this.renderCancelConfirmation()}
                <Breadcrumb title={"Check Order"} />
                <section className="section-b-space check-order-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title">
                                    <h2>Check Order</h2>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <form id="checkOrderForm" className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="form-row">
                                        <div className="col-sm-6">
                                            <label htmlFor="orderNum">Order Number</label>
                                            <input
                                                maxLength={30}
                                                onChange={(e) => this.setState({ orderNum: e.target.value, orderKey: '' })}
                                                value={this.state.orderNum}
                                                type="text"
                                                className="form-control"
                                                id="orderNum"
                                                placeholder="INV1000000"
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                maxLength={30}
                                                onChange={(e) => this.setState({ email: e.target.value, orderKey: '' })}
                                                value={this.state.email}
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="abc@example.com"
                                                required
                                            />
                                        </div>
                                    </div>
                                    {!this.state.loading ? (<div style={{ textAlign: 'center', marginTop: '30px' }}>
                                        <button type="submit" className="btn btn-solid" >
                                            Check Now
                                        </button>
                                    </div>) : null}
                                </form>
                            </div>
                        </div>
                        {this.state.loading ? (<div className="loading-cls" />) : null}
                        <p></p>
                        {orderDetails.length != 0 ? (
                            <div className="col-lg-12">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div style={{ position: "absolute", right: 40 }}>
                                            <i
                                                hidden={!orderDetails.invoice && orderDetails.invoice !== 1}
                                                className="fa fa-print text-info pr-2 pl-2"
                                                onClick={() => this.printInvoice()}
                                                style={{ fontSize: 22, cursor: "pointer" }}
                                            />
                                        </div>
                                        <div className="page-title" style={{ marginBottom: "20px" }}>
                                            <h2>Order Detail</h2>
                                        </div>

                                        {this.state.isLoading && orderDetails ? (
                                            <div className="loading-cls" />
                                        ) : (
                                                <Fragment>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "left" }}>
                                                            {/* <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/order`}>
                                                                <i className="fa fa-chevron-left" />
                                                                &nbsp; Back
                                                            </Link> */}
                                                        </div>
                                                        {/* {orderDetails.status.id === "2" || orderDetails.status.id === "7" || orderDetails.status.id === "3"? */}
                                                        {orderDetails.status.id === "3"?
                                                            <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "right" }}>
                                                                <button
                                                                    style={uploadbtn}
                                                                    type="button"
                                                                    onClick={() => this.orderReceived()}
                                                                    className="btn btn-solid">
                                                                    Order Received
                                                                </button>
                                                                {/* <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/order`}>
                                                                <i className="fa fa-chevron-left" />
                                                                &nbsp; Back
                                                                </Link> */}
                                                            </div>
                                                            : null}
                                                        {/* {isDealer ? (
                                                            <div className="col-6 text-right">
                                                                {orderDetails.buyer && orderDetails.buyer.id == 0 ? (
                                                                    <span className="badge bg-primary orderlist-tag ml-2">One Time Customer</span>
                                                                ) : orderDetails.buyer && orderDetails.buyer.id && orderDetails.buyer.id !== user.id ? (
                                                                    <span className="badge bg-primary orderlist-tag ml-2">Downline</span>
                                                                ) : null}
                                                            </div>
                                                        ) : null} */}
                                                    </div>
                                                    <div>
                                                        {this.renderOrderInfo()}
                                                        <div className="form-row">
                                                            {paymentDetails.payment_type == "BankTransfer" &&
                                                                orderDetails.status.id !== "5" &&
                                                                orderDetails.status.id !== "11" ? (
                                                                    <div className="col-lg-6">
                                                                        <div className="theme-border-box p-3 mb-3" >
                                                                            <form onSubmit={(e) => this.handleFileSubmit(e)}>
                                                                                <div>
                                                                                    <h4 style={{ fontSize: "15px" }}>Bank Transfer Reference</h4>
                                                                                    {orderDetails.payslip && orderDetails.status.id !== "1" ? (
                                                                                        <Fragment>
                                                                                            <p>
                                                                                                This order bank transfer reference have been uploaded, your order will be process
                                                                                                soon.
                                                                                            </p>
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <img
                                                                                                    style={{ border: "1px solid #ddd", height: "200px" }}
                                                                                                    alt="pay-slip"
                                                                                                    src={orderDetails.payslip}
                                                                                                />
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    ) : (
                                                                                            <Fragment>
                                                                                                {orderDetails.payslip && orderDetails.status.id === "1" ? (
                                                                                                    <Fragment>
                                                                                                        <div className="form-row">
                                                                                                            <p style={{ marginLeft: "6px", marginTop: "10px", marginBottom: "10px" }}>
                                                                                                                You may upload again if needed.
                                                </p>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <p style={{ color: "#222222", marginBottom: "3px" }}>{bankName}</p>
                                                                                                            <p style={{ color: "#222222", marginBottom: "3px" }}>{bankAccName}</p>
                                                                                                            <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                                                                                                            <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                                                                                                            <input
                                                                                                                hidden
                                                                                                                ref={(inputRef2) => (this.inputRef2 = inputRef2)}
                                                                                                                accept="image/png,image/jpeg,image/jpg"
                                                                                                                type="file"
                                                                                                                onChange={(e) => this.handleImageChange(e)}
                                                                                                            />
                                                                                                            <button
                                                                                                                style={uploadbtn}
                                                                                                                type="button"
                                                                                                                onClick={() => this.inputRef2.click()}
                                                                                                                className="btn btn-solid mr-2">
                                                                                                                Upload Payslip
                                                </button>
                                                                                                        </div>
                                                                                                        <button style={uploadbtn} type="submit" className="btn btn-solid">
                                                                                                            Confirm Upload
                                              </button>
                                                                                                    </Fragment>
                                                                                                ) : (
                                                                                                        <Fragment>
                                                                                                            <p style={{ color: "#222222", marginBottom: "3px" }}>{bankName}</p>
                                                                                                            <p style={{ color: "#222222", marginBottom: "3px" }}>{bankAccName}</p>
                                                                                                            <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                                                                                                            <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                                                                                                            <div className="form-row">
                                                                                                                <input
                                                                                                                    hidden
                                                                                                                    ref={(inputRef1) => (this.inputRef1 = inputRef1)}
                                                                                                                    accept="image/png,image/jpeg,image/jpg"
                                                                                                                    style={{ marginLeft: "5px", marginBottom: "5px" }}
                                                                                                                    type="file"
                                                                                                                    onChange={(e) => this.handleImageChange(e)}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div style={{ width: "100%" }}>
                                                                                                                {paySlipUrl ? (
                                                                                                                    <img
                                                                                                                        style={{ border: "1px solid #ddd", height: "200px" }}
                                                                                                                        alt="pay-slip"
                                                                                                                        src={paySlipUrl}
                                                                                                                    />
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                            <button
                                                                                                                style={uploadbtn}
                                                                                                                type="button"
                                                                                                                onClick={() => this.inputRef1.click()}
                                                                                                                className="btn btn-solid mr-2">
                                                                                                                Upload Payslip
                                              </button>
                                                                                                            <button
                                                                                                                hidden={!paySlipUrl}
                                                                                                                style={uploadbtn}
                                                                                                                type="submit"
                                                                                                                className="btn btn-solid">
                                                                                                                Confirm Upload
                                              </button>
                                                                                                        </Fragment>
                                                                                                    )}
                                                                                            </Fragment>
                                                                                        )}
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            {this.renderOrderSummary()}
                                                            {this.renderAddressInfo()}
                                                            {orderDetails.tracking_code ? (
                                                                <div className="col-lg-6">
                                                                    <div style={orderBox} className="pb-1">
                                                                        <h4 style={{ fontSize: "15px" }}>Delivery Info</h4>
                                                                        <p style={addLbl}>Shipping Agent : {orderDetails.shipping_agent}</p>
                                                                        <p style={addLbl}>Tracking Number : {orderDetails.tracking_code}</p>
                                                                        <div className="text-right">
                                                                            <button
                                                                                className="btn btn-solid small m-2 mb-0"
                                                                                onClick={() => window.linkTrack(orderDetails.tracking_code)}>
                                                                                Track
                                    </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {orderDetails.status.id !== "1" &&
                                                        orderDetails.status.id !== "2" &&
                                                        orderDetails.status.id !== "7" &&
                                                        !cancelSuccess ? (
                                                            ""
                                                        ) : (
                                                            <div style={{ textAlign: "right" }}>
                                                                <button
                                                                    style={cancelOrd}
                                                                    onClick={() => this.setState({ openModal: true })}
                                                                    className="btn theme-border-box text-theme">
                                                                    Cancel Order
                                                            </button>
                                                            </div>
                                                        )}
                                                </Fragment>
                                            )}
                                    </div>
                                </div>
                            </div>) : null}
                    </div>
                </section>
            </div>
        );
    }
}

const orderBox = {
    border: "1px solid #ddd",
    padding: "15px",
    marginBottom: "18px",
};

const uploadbtn = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "10px",
    marginTop: "10px",
};

const cancelOrd = {
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "10px",
};

const addLbl = {
    marginBottom: "3px",
};

const pymtPriceLbl = {
    fontWeight: "bold",
    float: "right",
    marginBottom: "0px",
};

const totalPriceLbl = {
    fontWeight: "bold",
    float: "right",
    marginBottom: "3px",
    fontSize: "20px",
};


export default CheckOrder;
