import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as DOWNLINE from "../../../api/downline";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart} from "../../../actions/index";
import { connect } from "react-redux";
import defaultAvatar from "../../../assets/images/defaultAvatar.jpg";
import SideMenu from "../sidemenu";
const mobileReg = /^[0-9\b]+$/;

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id ? props.match.params.id : 0,
      member: {},
      name: "",
      email: "",
      gender: "",
      mobileno: "",
      failMsg: [],
      updateErr: false,
      isOpen: false,
      avatarFile: null,
      loading: false,
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  renderErrorMsg(name) {
    if (this.state.failMsg && this.state.failMsg.find((msg) => msg.name == name) !== undefined)
      return this.state.failMsg.find((msg) => msg.name == name).msg;
    else return null;
  }

  handleSubmit(e) {
    e.preventDefault();
    let failMsg = [];
    let valid = true;
    if (!mobileReg.test(this.state.mobileno)) {
      valid = false;
      failMsg.push({ name: "mobileno", msg: "Mobile Number is invalid" });
    }
    if (valid) {
      this.setState({ failMsg: [] });
      const profileData = new FormData();
      profileData.append("name", this.state.name);
      profileData.append("email", this.state.email);
      profileData.append("mobileno", this.state.mobileno);

      DOWNLINE.addDownline(profileData)
        .then((res) => {
          toast.success("Add Member Successful!", { autoClose: 3000, position: "top-center" });
          this.props.history.push('/members/member-list')
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            if (err.data) {
              if (err.data.email) {
                failMsg.push({ name: "email", msg: err.data.email });
              }else if (err.data.mobileno){
                failMsg.push({ name: "mobileno", msg: err.data.mobileno });
              }
            }
            else if (err.message && err.http_code == 422) {
              toast.error(err.message, { autoClose: 3000, position: "top-center" });
            } 
          }
        });
    }
    this.setState({failMsg})
  }

  render() {
    const { loading } = this.state;
    const styles = {
      radioButton: {
        margin: "5px 15px",
      },
    };
    return (
      <div>
        <Breadcrumb title={"Members"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="member" />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>Add New Member</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "left" }}>
                        <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/member-list`}>
                          <i className="fa fa-chevron-left" />
                          &nbsp; Back
                        </Link>
                      </div>
                    </div>
                    <div className="box-account box-info">
                      <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" 
                                  maxLength={50} 
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                  value={this.state.name}
                            placeholder="" required />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="review">Email</label>
                            <input type="email" className="form-control" 
                              maxLength={50}
                              onChange={(e) => this.setState({ email: e.target.value })}
                              value={this.state.email}
                              id="email" required />
                              {this.renderErrorMsg("email") && this.state.email.length>0 ? (
                                <div className="text-danger small">{this.renderErrorMsg("email")}</div>
                              ) : null}
                          </div>
                        </div>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="mobileNumber">Mobile Number</label>
                            <input type="number" className="form-control" 
                               maxLength={18}
                               onChange={(e) => this.setState({ mobileno: e.target.value })}
                               value={this.state.mobileno} id="mobileNum" placeholder="" required />
                                 {this.renderErrorMsg("mobileno") && this.state.mobileno.length>0 ? (
                                <div className="text-danger small">{this.renderErrorMsg("mobileno")}</div>
                              ) : null}
                          </div>
                        </div>

                        <div className="text-right">
                          <button type="submit" className="btn btn-solid">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(null, mapDispatchToProps)(AddMember);
