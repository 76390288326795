

export const Product4 = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow:2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Product5 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}



export const Team4 = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 586,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
}



export const Slider2 = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const Slider3 = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Slider4 = {
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 586,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Slider5 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const Slider6 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
}

export const Slider7 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
}

// export const footerData = function() {
//     var contentwidth = window.innerWidth;
//     if ((contentwidth) < 750) {
//         document.querySelector(".footer-title h4").innerHTML += '<span class="according-menu"></span>';
//
//         var anchors = document.getElementsByClassName('footer-title');
//         for(var i = 0; i < anchors.length; i++) {
//             var anchor = anchors[i];
//             anchor.onclick = function() {
//                 var elems = document.querySelectorAll(".footer-title");
//                 [].forEach.call(elems, function(elemt) {
//                     elemt.classList.remove("active");
//                     var el = elemt.nextElementSibling;
//
//                     el.style.height = el.offsetHeight + 'px'
//                     el.style.transitionProperty = `height, margin, padding`
//                     el.style.transitionDuration = '500ms'
//                     el.offsetHeight // eslint-disable-line no-unused-expressions
//                     el.style.overflow = 'hidden'
//                     el.style.height = 0
//                     el.style.paddingTop = 0
//                     el.style.paddingBottom = 0
//                     el.style.marginTop = 0
//                     el.style.marginBottom = 0
//                     el.style.display = 'none'
//                     el.style.removeProperty('height')
//                     el.style.removeProperty('padding-top')
//                     el.style.removeProperty('padding-bottom')
//                     el.style.removeProperty('margin-top')
//                     el.style.removeProperty('margin-bottom')
//                     el.style.removeProperty('overflow')
//                     el.style.removeProperty('transition-duration')
//                     el.style.removeProperty('transition-property')
//
//                 });
//
//                 this.classList.add('active');
//                 var element = this.nextElementSibling;
//                 element.style.removeProperty('display')
//                 let display = window.getComputedStyle(element).display
//                 if (display === 'none') display = 'block'
//                 element.style.display = display
//                 let height = element.offsetHeight
//                 element.style.overflow = 'hidden'
//                 element.style.height = 0
//                 element.style.paddingTop = 0
//                 element.style.paddingBottom = 0
//                 element.style.marginTop = 0
//                 element.style.marginBottom = 0
//                 element.offsetHeight // eslint-disable-line no-unused-expressions
//                 element.style.transitionProperty = `height, margin, padding`
//                 element.style.transitionDuration = '500ms'
//                 element.style.height = height + 'px'
//                 element.style.removeProperty('padding-top')
//                 element.style.removeProperty('padding-bottom')
//                 element.style.removeProperty('margin-top')
//                 element.style.removeProperty('margin-bottom')
//                 window.setTimeout(function () {
//                     element.style.removeProperty('height')
//                     element.style.removeProperty('overflow')
//                     element.style.removeProperty('transition-duration')
//                     element.style.removeProperty('transition-property')
//                 }, 500)
//             }
//         }
//
//         var elems = document.querySelectorAll(".footer-title");
//         [].forEach.call(elems, function(elemt) {
//             let el = elemt.nextElementSibling;
//             el.style = "display: none";
//         });
//     } else {
//         var elems = document.querySelectorAll(".footer-title");
//         [].forEach.call(elems, function(elemt) {
//             let el = elemt.nextElementSibling;
//             el.style = "display: block";
//         });
//     }
// }

export const SlideUpDown = function(classnames) {
        var accords = document.querySelectorAll("."+classnames+" h4");
        [].forEach.call(accords, function(elemt) {
            elemt.innerHTML += '<span class="according-menu"></span>';
        })

        var anchors = document.getElementsByClassName(classnames);
        for(var i = 0; i < anchors.length; i++) {
            var anchor = anchors[i];
            anchor.onclick = function() {
                var elems = document.querySelectorAll("."+classnames);
                [].forEach.call(elems, function(elemt) {
                    elemt.classList.remove("active");
                    var el = elemt.nextElementSibling;
                    el.style.height = el.offsetHeight + 'px'
                    el.style.transitionProperty = `height, margin, padding`
                    el.style.transitionDuration = '500ms'
                    el.offsetHeight // eslint-disable-line no-unused-expressions
                    el.style.overflow = 'hidden'
                    el.style.height = 0
                    el.style.paddingTop = 0
                    el.style.paddingBottom = 0
                    el.style.marginTop = 0
                    el.style.marginBottom = 0
                    el.style.display = 'none'
                    el.style.removeProperty('height')
                    el.style.removeProperty('padding-top')
                    el.style.removeProperty('padding-bottom')
                    el.style.removeProperty('margin-top')
                    el.style.removeProperty('margin-bottom')
                    el.style.removeProperty('overflow')
                    el.style.removeProperty('transition-duration')
                    el.style.removeProperty('transition-property')

                });

                this.classList.add('active');
                var element = this.nextElementSibling;
                element.style.removeProperty('display')
                let display = window.getComputedStyle(element).display

                if(element.classList.contains('show')) {
                    element.classList.remove('show');

                    this.classList.remove("active");
                    element.style.height = element.offsetHeight + 'px'
                    element.style.transitionProperty = `height, margin, padding`
                    element.style.transitionDuration = '500ms'
                    element.offsetHeight // eslint-disable-line no-unused-expressions
                    element.style.overflow = 'hidden'
                    element.style.height = 0
                    element.style.paddingTop = 0
                    element.style.paddingBottom = 0
                    element.style.marginTop = 0
                    element.style.marginBottom = 0
                    element.style.display = 'none'
                    element.style.transitionProperty = `height, margin, padding`
                    element.style.transitionDuration = '500ms'
                    element.style.removeProperty('height')
                    element.style.removeProperty('padding-top')
                    element.style.removeProperty('padding-bottom')
                    element.style.removeProperty('margin-top')
                    element.style.removeProperty('margin-bottom')
                    window.setTimeout(function () {
                        element.style.removeProperty('overflow')
                        element.style.removeProperty('transition-duration')
                        element.style.removeProperty('transition-property')
                    }, 500)

                }else{
                    element.classList.add('show');
                    if (display === 'none') display = 'block'
                    element.style.display = display
                    let height = element.offsetHeight
                    element.style.overflow = 'hidden'
                    element.style.height = 0
                    element.style.paddingTop = 0
                    element.style.paddingBottom = 0
                    element.style.marginTop = 0
                    element.style.marginBottom = 0
                    element.offsetHeight // eslint-disable-line no-unused-expressions
                    element.style.transitionProperty = `height, margin, padding`
                    element.style.transitionDuration = '500ms'
                    element.style.height = height + 'px'
                    element.style.removeProperty('padding-top')
                    element.style.removeProperty('padding-bottom')
                    element.style.removeProperty('margin-top')
                    element.style.removeProperty('margin-bottom')
                    window.setTimeout(function () {
                        element.style.removeProperty('height')
                        element.style.removeProperty('overflow')
                        element.style.removeProperty('transition-duration')
                        element.style.removeProperty('transition-property')
                    }, 500)
                }
            }
        }

        var elems = document.querySelectorAll("."+classnames);
        [].forEach.call(elems, function(elemt) {
            let el = elemt.nextElementSibling;
            el.style = "display: none";
        });

}


// SVG Icons

export const svgFreeShipping = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="52px" height="52px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
<g>
<path fill="#91C73D" d="M16.563,9.556H5.159c-0.928,0-1.684,0.754-1.684,1.682v14.764c0,0.45,0.365,0.814,0.814,0.814
   c0.449,0,0.814-0.364,0.814-0.814V11.237c0-0.029,0.023-0.055,0.055-0.055H36.42c0.031,0,0.055,0.024,0.055,0.055v14.764
   c0,0.45,0.365,0.814,0.814,0.814s0.814-0.364,0.814-0.814v-0.923h9.604c0.002,0,0.002,0,0.002,0c0.002,0,0.002,0,0.004,0
   c1.182,0.001,2.186,0.776,2.529,1.846h-2.531c-0.449,0-0.814,0.364-0.814,0.813v1.736c0,1.407,1.146,2.551,2.551,2.551h0.923v3.584
   h-2.13c-0.698-2.021-2.616-3.475-4.87-3.475c-2.257,0-4.175,1.454-4.873,3.475h-0.394v-6.135c0-0.449-0.365-0.813-0.813-0.813
   c-0.449,0-0.813,0.364-0.813,0.813v6.135H19.585c-0.699-2.021-2.619-3.476-4.873-3.476s-4.174,1.455-4.873,3.476h-4.68
   c-0.031,0-0.055-0.024-0.055-0.056v-1.79h3.527c0.449,0,0.814-0.365,0.814-0.814s-0.365-0.814-0.814-0.814H0.817
   c-0.451,0-0.814,0.365-0.814,0.814s0.363,0.814,0.814,0.814h2.658v1.79c0,0.929,0.756,1.683,1.684,1.683h4.396
   c0,0.019,0,0.037,0,0.056c0,2.843,2.313,5.155,5.156,5.155c2.842,0,5.156-2.313,5.156-5.155c0-0.019-0.002-0.037-0.002-0.056
   h18.348c0,0.019,0,0.037,0,0.056c0,2.843,2.313,5.155,5.156,5.155c2.842,0,5.154-2.313,5.154-5.155c0-0.019,0-0.037,0-0.056h2.66
   c0.448,0,0.813-0.363,0.813-0.813v-8.685c0-2.145-1.582-3.926-3.642-4.238l-1.346-5.387c0.369-0.077,0.645-0.404,0.645-0.797
   v-0.867c0-1.887-1.532-3.42-3.42-3.42h-6.133v-1.791c0-0.928-0.754-1.684-1.682-1.684H25.016h-8.453V9.556z M38.105,14.657h6.133
   c0.988,0,1.791,0.804,1.791,1.791v0.055h-7.924V14.657z M38.105,23.45v-5.319h7.233l1.33,5.319H38.105z M14.712,40.817
   c-1.945,0-3.529-1.582-3.529-3.526c0-1.945,1.584-3.528,3.529-3.528s3.527,1.583,3.527,3.528
   C18.239,39.235,16.657,40.817,14.712,40.817z M43.37,40.817c-1.945,0-3.529-1.582-3.529-3.526c0-1.945,1.584-3.528,3.529-3.528
   c1.944,0,3.526,1.583,3.526,3.528C46.896,39.235,45.314,40.817,43.37,40.817z M50.37,30.397h-0.923
   c-0.508,0-0.922-0.414-0.922-0.924v-0.923h1.845V30.397L50.37,30.397z"/>
<g>
   <g>
       <path fill="#91C73D" d="M14.712,35.608c-0.928,0-1.684,0.754-1.684,1.683c0,0.928,0.756,1.683,1.684,1.683
           c0.928,0,1.682-0.755,1.682-1.683C16.394,36.362,15.64,35.608,14.712,35.608z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M43.37,35.608c-0.929,0-1.685,0.754-1.685,1.683c0,0.928,0.756,1.683,1.685,1.683
           c0.928,0,1.682-0.755,1.682-1.683C45.052,36.362,44.298,35.608,43.37,35.608z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M33.816,32.134H21.659c-0.449,0-0.814,0.365-0.814,0.814s0.363,0.813,0.814,0.813h12.157
           c0.449,0,0.813-0.364,0.813-0.813S34.268,32.134,33.816,32.134z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M12.974,28.66H2.554c-0.449,0-0.814,0.364-0.814,0.813c0,0.45,0.365,0.814,0.814,0.814h10.42
           c0.451,0,0.814-0.364,0.814-0.814C13.788,29.024,13.425,28.66,12.974,28.66z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M12.974,16.503H9.501c-0.449,0-0.814,0.364-0.814,0.813v6.947c0,0.45,0.365,0.814,0.814,0.814
           s0.814-0.364,0.814-0.814v-2.658h1.791c0.449,0,0.814-0.365,0.814-0.814s-0.365-0.813-0.814-0.813h-1.791v-1.847h2.658
           c0.451,0,0.814-0.364,0.814-0.813C13.788,16.867,13.425,16.503,12.974,16.503z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M19.382,21.985c0.814-0.533,1.354-1.453,1.354-2.498c0-1.646-1.338-2.984-2.984-2.984H15.58
           c-0.449,0-0.814,0.364-0.814,0.813v6.947c0,0.45,0.365,0.814,0.814,0.814c0.451,0,0.814-0.364,0.814-0.814v-1.791h1.355
           l1.496,2.243c0.156,0.235,0.414,0.362,0.678,0.362c0.154,0,0.313-0.044,0.451-0.137c0.373-0.25,0.475-0.756,0.225-1.129
           L19.382,21.985z M17.751,20.845h-1.357v-2.714h1.357c0.748,0,1.357,0.608,1.357,1.356S18.499,20.845,17.751,20.845z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M26.001,23.45h-2.66v-1.85c0.018,0.002,0.037,0.004,0.055,0.004h1.736c0.449,0,0.814-0.365,0.814-0.814
           s-0.365-0.813-0.814-0.813h-1.736c-0.018,0-0.037,0-0.055,0.002v-1.849h2.66c0.449,0,0.813-0.364,0.813-0.813
           s-0.365-0.813-0.813-0.813h-3.475c-0.449,0-0.814,0.364-0.814,0.813v6.947c0,0.449,0.365,0.813,0.814,0.813h3.475
           c0.449,0,0.813-0.364,0.813-0.813C26.814,23.813,26.45,23.45,26.001,23.45z"/>
   </g>
</g>
<g>
   <g>
       <path fill="#91C73D" d="M32.079,23.45h-2.658v-1.846h0.922c0.449,0,0.813-0.365,0.813-0.814s-0.364-0.813-0.813-0.813h-0.922
           v-1.847h2.658c0.45,0,0.813-0.364,0.813-0.813s-0.363-0.813-0.813-0.813h-3.474c-0.449,0-0.813,0.364-0.813,0.813v6.947
           c0,0.449,0.364,0.813,0.813,0.813h3.474c0.45,0,0.813-0.364,0.813-0.813C32.895,23.813,32.529,23.45,32.079,23.45z"/>
   </g>
</g>
</g>
</svg>`;
export const svgservice = `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="48px" height="48px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
<path fill="#91C73D" d="M41.096,44.788l-0.455-2.285c-0.076-0.381-0.445-0.628-0.826-0.553c-0.383,0.077-0.629,0.446-0.553,0.827
l0.454,2.285c0.157,0.793-0.448,1.53-1.256,1.53h-4.106V43.4c0-0.387-0.316-0.702-0.705-0.702s-0.703,0.315-0.703,0.702v3.192
H20.827c-0.388,0-0.703,0.314-0.703,0.703c0,0.39,0.315,0.704,0.703,0.704H38.46C40.156,48,41.425,46.448,41.096,44.788z M40,39.281
l-0.238-1.201c-0.468-2.337-2.168-3.292-3.14-3.655c-0.364-0.135-0.981-0.272-8.136-2.047v-0.487h7.611
c0.787,0,1.426-0.64,1.426-1.427v-9.155c0.473-0.656,0.74-1.455,0.74-2.304c0-0.848-0.269-1.647-0.74-2.303v-3.216
c0-3.61-1.412-7.004-3.98-9.557C28.72-0.867,21.167-1.314,15.849,2.816c-0.307,0.238-0.362,0.68-0.124,0.986
c0.238,0.307,0.68,0.363,0.986,0.125c4.748-3.687,11.516-3.3,15.84,1c2.301,2.287,3.567,5.327,3.567,8.56v1.755
c-0.201-0.526-0.617-0.942-1.142-1.142v-0.614c0-6.028-4.904-10.934-10.935-10.934c-6.028,0-10.934,4.905-10.934,10.934V14.1
c-0.524,0.2-0.942,0.617-1.142,1.142v-1.615c0-2.74,0.883-5.334,2.555-7.502c0.237-0.307,0.18-0.749-0.127-0.986
c-0.308-0.237-0.749-0.18-0.986,0.127c-1.864,2.416-2.848,5.307-2.848,8.361v3.075c-0.472,0.656-0.74,1.456-0.74,2.304
c0,0.848,0.268,1.648,0.74,2.304v8.859c0,0.949,0.773,1.722,1.723,1.722h7.257v0.482c-0.592,0.146-7.331,1.816-8.088,2.037
l0.001,0.002c-0.016,0.006-0.031,0.013-0.046,0.02l-0.002-0.006c-0.971,0.363-2.671,1.318-3.138,3.655
c-0.562,2.823-0.957,4.794-1.363,6.821C6.583,46.505,7.811,48,9.443,48h8.102c0.388,0,0.703-0.314,0.703-0.703
s-0.315-0.703-0.703-0.703H15.08v-3.191c0-0.391-0.315-0.703-0.703-0.703c-0.389,0-0.704,0.313-0.704,0.703v3.191H9.444
c-0.748,0-1.309-0.685-1.162-1.417c0.501-2.502,0.861-4.299,1.364-6.823c0.463-2.32,2.614-2.695,2.735-2.752l4.319-1.072l1.95,2.303
l0,0l2.54,2.998c0.731,0.863,1.72,1.406,2.818,1.406c0.002,0,0.003,0,0.004,0c1.047,0,2.046-0.496,2.814-1.396l4.516-5.305
l4.318,1.07v-0.002c0.053,0.021,0.067,0.021,0.493,0.147c0.699,0.267,1.896,0.955,2.228,2.603l0.239,1.203
c0.076,0.381,0.445,0.629,0.826,0.553C39.827,40.035,40.074,39.663,40,39.281z M19.163,28.109h2.11
c0.285,0.743,1.004,1.272,1.846,1.272h1.786c1.092,0,1.979-0.887,1.979-1.978c0-1.09-0.887-1.978-1.979-1.978H23.12
c-0.842,0-1.562,0.53-1.846,1.274h-2.295c-0.498,0-0.98-0.065-1.434-0.191c-1.126-1.463-1.755-3.28-1.755-5.142v-1.677
c0-0.139,0.056-0.267,0.157-0.362c0.092-0.087,0.21-0.135,0.335-0.135c0.01,0,0.02,0.001,0.03,0.001
c0.331,0.02,0.654,0.03,0.971,0.03c6.4-0.001,10.21-3.906,11.73-5.885c0.796,1.424,1.976,1.865,2.845,1.979
c0.232,0.03,0.406,0.227,0.406,0.458v5.716C32.264,28.258,24.507,32.114,19.163,28.109z M22.547,27.405
c0-0.313,0.256-0.57,0.571-0.57h1.786c0.315,0,0.571,0.257,0.571,0.57c0,0.315-0.256,0.572-0.571,0.572H23.12
C22.805,27.979,22.547,27.722,22.547,27.405z M36.249,17.356c0.015,0.02,0.028,0.039,0.047,0.057
c0.354,0.441,0.563,0.997,0.563,1.592c0,0.595-0.207,1.151-0.563,1.592c-0.019,0.019-0.032,0.037-0.047,0.057V17.356z M36.119,22.77
v7.694c0,0.013-0.01,0.021-0.021,0.021h-7.611v-0.43c2.424-1.262,4.229-3.519,4.898-6.224c0.268,0.134,0.568,0.212,0.889,0.212
C35.113,24.043,35.835,23.513,36.119,22.77z M33.7,15.945c0-0.314,0.257-0.571,0.573-0.571c0.313,0,0.569,0.257,0.569,0.571v0.061v6
v0.061c0,0.315-0.257,0.572-0.569,0.572c-0.316,0-0.573-0.257-0.573-0.572V15.945z M24.042,3.959c5.254,0,9.529,4.273,9.529,9.527
V14.1c-0.19,0.072-0.365,0.171-0.521,0.295c-0.273-0.247-0.621-0.417-1.009-0.467c-0.845-0.111-1.483-0.603-1.899-1.461
c-0.402-0.828-1.542-0.933-2.087-0.191c-1.257,1.711-4.749,5.545-10.78,5.545c-0.287,0-0.58-0.008-0.878-0.026
c-0.206-0.012-0.411,0.011-0.607,0.064v-1.914c0-0.842-0.53-1.562-1.274-1.846v-0.613h-0.001
C14.514,8.232,18.789,3.959,24.042,3.959z M14.895,24.488c-0.106-0.203-0.19-0.414-0.253-0.63c0.019-0.008,0.038-0.018,0.057-0.027
C14.756,24.052,14.821,24.271,14.895,24.488z M13.239,22.005v-6v-0.061c0-0.314,0.257-0.571,0.572-0.571s0.571,0.257,0.571,0.571
v3.748v1.677v0.696c0,0.315-0.256,0.572-0.571,0.572s-0.572-0.256-0.572-0.571V22.005z M11.833,20.655
c-0.014-0.02-0.03-0.039-0.046-0.057c-0.355-0.441-0.562-0.997-0.562-1.592c0-0.595,0.207-1.151,0.562-1.592
c0.017-0.018,0.032-0.037,0.046-0.057V20.655z M12.282,30.482c-0.175,0-0.316-0.142-0.316-0.314V22.77
c0.214,0.56,0.674,0.999,1.249,1.182c0.188,0.865,0.623,1.671,1.271,2.352c0.602,0.632,1.369,1.113,2.23,1.421
c0.221,0.263,0.458,0.514,0.708,0.753c0.645,0.614,1.356,1.13,2.116,1.54v0.467h-7.258V30.482z M27.328,37.08l-1.572,1.846
c-0.496,0.584-1.115,0.904-1.744,0.904c-0.001,0-0.002,0-0.002,0c-0.63-0.001-1.251-0.324-1.747-0.909l-1.56-1.841H27.328
L27.328,37.08z M28.523,35.675h-9.012l-1.289-1.521l2.188-0.543c0.314-0.077,0.535-0.366,0.535-0.689c0-0.005,0-0.01,0-0.016V30.63
c0.981,0.336,2.019,0.511,3.082,0.511c1.013,0,2.051-0.156,3.055-0.49v2.265c0,0.005,0,0.01,0,0.017c0,0.322,0.22,0.604,0.533,0.682
l2.203,0.547L28.523,35.675z"/>
</svg>`;

export const svgoffer = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="48px" height="48px" viewBox="6 6 48 48" enable-background="new 6 6 48 48" xml:space="preserve">
<g>
<g>
   <path fill="#91C73D" d="M34.408,11.772c-0.506-0.123-1.012,0.188-1.133,0.691s0.188,1.01,0.691,1.131
       c7.601,1.832,12.906,8.578,12.906,16.405c0,9.306-7.57,16.875-16.876,16.875c-9.307,0-16.876-7.569-16.876-16.875
       c0-7.828,5.307-14.573,12.904-16.405c0.505-0.121,0.813-0.627,0.693-1.131c-0.123-0.504-0.629-0.813-1.132-0.691
       c-4.021,0.969-7.662,3.293-10.255,6.543c-2.672,3.349-4.085,7.391-4.085,11.685c0,10.339,8.412,18.75,18.751,18.75
       c10.338,0,18.751-8.41,18.751-18.75c0-4.294-1.412-8.334-4.085-11.685C42.071,15.065,38.43,12.741,34.408,11.772z"/>
   <path fill="#91C73D" d="M53.437,27.745l-1.099-2.046l0.185-2.531c0.176-1.622-0.472-3.204-1.732-4.235l-1.82-1.492l-0.716-2.156
       c-0.401-1.649-1.653-2.976-3.278-3.468l-2.248-0.678l-1.722-1.99c-1.02-1.197-2.561-1.821-4.124-1.668L34.55,7.714l-2.294-1.15
       c-1.413-0.752-3.096-0.754-4.51,0L25.7,7.661l-2.531-0.184c-1.623-0.176-3.205,0.472-4.236,1.733l-1.49,1.82l-2.157,0.716
       c-1.649,0.4-2.977,1.654-3.467,3.279l-0.68,2.248l-1.99,1.721c-1.197,1.018-1.82,2.559-1.666,4.123l0.231,2.333l-1.149,2.293
       c-0.754,1.413-0.754,3.097,0,4.511l1.097,2.046L7.478,36.83c-0.176,1.622,0.473,3.205,1.733,4.235l1.821,1.492l0.716,2.156
       c0.401,1.648,1.653,2.977,3.278,3.467l2.248,0.68l1.722,1.988c1.019,1.198,2.56,1.821,4.124,1.668l2.333-0.231l2.293,1.147
       c0.707,0.379,1.48,0.568,2.255,0.568c0.773,0,1.548-0.188,2.253-0.564l2.047-1.098l2.528,0.184
       c1.622,0.177,3.205-0.472,4.238-1.731l1.49-1.819l2.155-0.717c1.649-0.4,2.978-1.652,3.468-3.277l0.68-2.248l1.988-1.723
       c1.197-1.021,1.82-2.562,1.668-4.124l-0.231-2.332l1.149-2.293C54.188,30.843,54.188,29.158,53.437,27.745L53.437,27.745z
        M51.776,31.381c-0.006,0.008-0.008,0.016-0.014,0.021l-1.277,2.548c-0.077,0.157-0.11,0.337-0.094,0.514l0.26,2.603
       c0.095,0.951-0.287,1.891-1.021,2.516l-2.199,1.905c-0.137,0.115-0.232,0.269-0.285,0.438l-0.756,2.509
       c-0.304,1-1.123,1.77-2.144,2.006c-0.027,0.006-0.058,0.015-0.082,0.022l-2.454,0.813c-0.169,0.057-0.316,0.16-0.432,0.297
       l-1.662,2.031c-0.634,0.771-1.604,1.166-2.597,1.055c-0.012-0.002-0.023-0.002-0.037-0.004l-2.816-0.203
       c-0.178-0.014-0.354,0.023-0.512,0.107l-2.283,1.225c-0.859,0.46-1.89,0.458-2.751-0.004c-0.008-0.006-0.017-0.008-0.022-0.014
       l-2.547-1.277c-0.132-0.064-0.275-0.1-0.421-0.1c-0.03,0-0.062,0.002-0.093,0.006l-2.603,0.258
       c-0.953,0.096-1.891-0.285-2.517-1.021l-1.903-2.199c-0.117-0.137-0.269-0.232-0.438-0.285l-2.507-0.756
       c-1-0.304-1.771-1.123-2.006-2.144c-0.006-0.026-0.014-0.058-0.023-0.082l-0.814-2.454c-0.056-0.169-0.158-0.316-0.295-0.43
       l-2.032-1.666c-0.772-0.632-1.168-1.603-1.055-2.595c0-0.014,0.002-0.024,0.002-0.037l0.205-2.818
       c0.012-0.177-0.025-0.354-0.109-0.512l-1.225-2.283c-0.459-0.859-0.457-1.889,0.006-2.751c0.004-0.008,0.008-0.017,0.012-0.022
       l1.277-2.547c0.08-0.16,0.112-0.337,0.094-0.514l-0.258-2.603c-0.094-0.951,0.285-1.891,1.021-2.517l2.201-1.903
       c0.134-0.117,0.232-0.269,0.285-0.438l0.757-2.507c0.302-1,1.123-1.771,2.143-2.006c0.027-0.008,0.055-0.016,0.082-0.023
       l2.454-0.814c0.168-0.056,0.317-0.158,0.431-0.295l1.664-2.031c0.631-0.772,1.602-1.166,2.594-1.055
       c0.014,0.002,0.025,0.002,0.037,0.002l2.818,0.205c0.178,0.012,0.354-0.025,0.512-0.109l2.283-1.225
       c0.861-0.459,1.89-0.457,2.751,0.006c0.008,0.004,0.016,0.008,0.021,0.012l2.548,1.277c0.157,0.08,0.337,0.112,0.515,0.094
       l2.602-0.258c0.951-0.095,1.891,0.285,2.517,1.021l1.904,2.201c0.115,0.134,0.269,0.232,0.438,0.283l2.509,0.758
       c0.998,0.302,1.769,1.123,2.006,2.142c0.006,0.028,0.014,0.057,0.021,0.083l0.813,2.454c0.058,0.169,0.158,0.316,0.297,0.43
       l2.029,1.663c0.772,0.632,1.168,1.603,1.058,2.595c-0.003,0.014-0.005,0.025-0.005,0.039l-0.203,2.817
       c-0.014,0.177,0.023,0.354,0.107,0.511l1.226,2.284C52.239,29.49,52.237,30.52,51.776,31.381L51.776,31.381z"/>
   <path fill="#91C73D" d="M25.931,24.893c-0.149-0.317-0.473-0.537-0.847-0.537h-4.955c-0.518,0-0.938,0.42-0.938,0.938v14.188
       c0,0.52,0.42,0.939,0.938,0.939h4.955c0.39,0,0.722-0.235,0.864-0.574c0.714,0.102,1.451,0.305,2.217,0.517
       c1.152,0.32,2.342,0.648,3.583,0.648h5.412c1.673,0,3.029-0.888,3.628-2.372c0.291-0.725,0.341-1.477,0.172-2.104
       c0.312-0.28,0.574-0.628,0.771-1.035c0.404-0.824,0.484-1.751,0.256-2.531c0.428-0.41,0.744-0.955,0.91-1.596
       c0.154-0.602,0.152-1.19,0.016-1.703c0.846-0.497,1.416-1.401,1.416-2.419c0-0.799-0.313-1.535-0.877-2.072
       c-0.541-0.517-1.266-0.802-2.033-0.802h-7.629c-0.58-0.727-0.761-1.713-0.433-2.508c0.68-1.631,0.638-3.01-0.135-4.473
       c-0.572-1.086-2.111-2.168-3.406-1.984c-0.781,0.111-1.348,0.665-1.514,1.479c-0.048,0.239-0.062,0.498-0.076,0.799
       c-0.039,0.738-0.092,1.75-0.729,2.974c-0.433,0.829-0.577,1.681-0.704,2.433C26.61,24.173,26.522,24.692,25.931,24.893
       L25.931,24.893z M21.067,26.23h3.08v12.314h-3.08V26.23z M28.642,23.41c0.109-0.645,0.223-1.313,0.519-1.878
       c0.827-1.584,0.897-2.937,0.938-3.745c0.012-0.215,0.021-0.418,0.043-0.521c0,0,0,0,0-0.002c0.324,0.006,1.126,0.444,1.421,1.008
       c0.515,0.975,0.529,1.754,0.063,2.879c-0.421,1.016-0.392,2.191,0.026,3.238c-0.457,0.063-0.807,0.454-0.807,0.928
       c0,0.518,0.418,0.938,0.938,0.938h9.633c0.285,0,0.549,0.102,0.741,0.284c0.134,0.127,0.294,0.354,0.294,0.714
       c0,0.503-0.463,0.938-1.01,0.95l-2.413,0.053c-0.518,0.011-0.928,0.44-0.916,0.958c0.013,0.512,0.43,0.917,0.939,0.917
       c0.006,0,0.014,0,0.02,0l2.011-0.043c0.066,0.187,0.1,0.54-0.038,0.945c-0.116,0.346-0.433,0.925-1.229,0.925
       c-0.519,0-0.938,0.419-0.938,0.938c0,0.519,0.42,0.938,0.938,0.938c0.144,0,0.283-0.008,0.421-0.025
       c0.012,0.268-0.043,0.575-0.189,0.875c-0.173,0.353-0.513,0.771-1.155,0.771c-0.519,0-0.938,0.42-0.938,0.938
       c0,0.519,0.42,0.938,0.938,0.938c0.099,0,0.192-0.004,0.287-0.015c-0.002,0.182-0.039,0.406-0.141,0.651
       c-0.146,0.354-0.625,1.177-1.879,1.177h-5.412c-0.982,0-2.001-0.283-3.082-0.58c-0.858-0.237-1.739-0.48-2.644-0.592V26.79
       C28.132,26.407,28.423,24.698,28.642,23.41z"/>
   <path fill="#91C73D" d="M30,13.124c0.516,0,0.936-0.42,0.936-0.938c0-0.519-0.42-0.938-0.936-0.938l0,0
       c-0.518,0-0.938,0.419-0.938,0.938C29.062,12.704,29.481,13.124,30,13.124z"/>
</g>
</g>
</svg>
`;
export const svgpayment = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px">\n' +
    '                                                 <g>\n' +
    '                                                    <g>\n' +
    '                                                       <g>\n' +
    '                                                          <path d="M498.409,175.706L336.283,13.582c-8.752-8.751-20.423-13.571-32.865-13.571c-12.441,0-24.113,4.818-32.865,13.569     L13.571,270.563C4.82,279.315,0,290.985,0,303.427c0,12.442,4.82,24.114,13.571,32.864l19.992,19.992     c0.002,0.001,0.003,0.003,0.005,0.005c0.002,0.002,0.004,0.004,0.006,0.006l134.36,134.36H149.33     c-5.89,0-10.666,4.775-10.666,10.666c0,5.89,4.776,10.666,10.666,10.666h59.189c0.014,0,0.027,0.001,0.041,0.001     s0.027-0.001,0.041-0.001l154.053,0.002c5.89,0,10.666-4.776,10.666-10.666c0-5.891-4.776-10.666-10.666-10.666l-113.464-0.002     L498.41,241.434C516.53,223.312,516.53,193.826,498.409,175.706z M483.325,226.35L226.341,483.334     c-4.713,4.712-11.013,7.31-17.742,7.32h-0.081c-6.727-0.011-13.025-2.608-17.736-7.32L56.195,348.746L302.99,101.949     c4.165-4.165,4.165-10.919,0-15.084c-4.166-4.165-10.918-4.165-15.085,0.001L41.11,333.663l-12.456-12.456     c-4.721-4.721-7.321-11.035-7.321-17.779c0-6.744,2.6-13.059,7.322-17.781L285.637,28.665c4.722-4.721,11.037-7.321,17.781-7.321     c6.744,0,13.059,2.6,17.781,7.322l57.703,57.702l-246.798,246.8c-4.165,4.164-4.165,10.918,0,15.085     c2.083,2.082,4.813,3.123,7.542,3.123c2.729,0,5.459-1.042,7.542-3.124l246.798-246.799l89.339,89.336     C493.128,200.593,493.127,216.546,483.325,226.35z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M262.801,308.064c-4.165-4.165-10.917-4.164-15.085,0l-83.934,83.933c-4.165,4.165-4.165,10.918,0,15.085     c2.083,2.083,4.813,3.124,7.542,3.124c2.729,0,5.459-1.042,7.542-3.124l83.934-83.933     C266.966,318.982,266.966,312.229,262.801,308.064z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M228.375,387.741l-34.425,34.425c-4.165,4.165-4.165,10.919,0,15.085c2.083,2.082,4.813,3.124,7.542,3.124     c2.731,0,5.459-1.042,7.542-3.124l34.425-34.425c4.165-4.165,4.165-10.919,0-15.085     C239.294,383.575,232.543,383.575,228.375,387.741z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M260.054,356.065l-4.525,4.524c-4.166,4.165-4.166,10.918-0.001,15.085c2.082,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.459-1.042,7.541-3.125l4.525-4.524c4.166-4.165,4.166-10.918,0.001-15.084     C270.974,351.901,264.219,351.9,260.054,356.065z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M407.073,163.793c-2-2-4.713-3.124-7.542-3.124c-2.829,0-5.541,1.124-7.542,3.124l-45.255,45.254     c-2,2.001-3.124,4.713-3.124,7.542s1.124,5.542,3.124,7.542l30.17,30.167c2.083,2.083,4.813,3.124,7.542,3.124     c2.731,0,5.459-1.042,7.542-3.124l45.253-45.252c4.165-4.165,4.165-10.919,0-15.084L407.073,163.793z M384.445,231.673     l-15.085-15.084l30.17-30.169l15.084,15.085L384.445,231.673z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M320.339,80.186c2.731,0,5.461-1.042,7.543-3.126l4.525-4.527c4.164-4.166,4.163-10.92-0.003-15.084     c-4.165-4.164-10.92-4.163-15.084,0.003l-4.525,4.527c-4.164,4.166-4.163,10.92,0.003,15.084     C314.881,79.146,317.609,80.186,320.339,80.186z" fill="#ff4c3b"/>\n' +
    '                                                          <path d="M107.215,358.057l-4.525,4.525c-4.165,4.164-4.165,10.918,0,15.085c2.083,2.082,4.813,3.123,7.542,3.123     s5.459-1.041,7.542-3.123l4.525-4.525c4.165-4.166,4.165-10.92,0-15.085C118.133,353.891,111.381,353.891,107.215,358.057z" fill="#ff4c3b"/>\n' +
    '                                                       </g>\n' +
    '                                                    </g>\n' +
    '                                                 </g>\n' +
    '                                                </svg>';
export const svgtigerpaw = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="38px" height="38px" viewBox="0 0 38 38" enable-background="new 0 0 38 38" xml:space="preserve">
<g>
<path fill="#E1E7EB" d="M20.666,29.773c-0.005-0.014-0.011-0.023-0.013-0.039c0-0.008-0.006-0.02-0.012-0.025
   c-0.426-1.335-0.41-2.82,0.146-4.222c0.005-0.018,0.015-0.035,0.02-0.053c0.004-0.01,0.008-0.023,0.014-0.035
   c0.9-2.267,0.433-4.946-1.404-6.78c-0.006-0.005-0.012-0.01-0.018-0.016c-0.006-0.005-0.01-0.012-0.016-0.017
   c-1.834-1.835-4.515-2.304-6.779-1.404c-0.011,0.006-0.024,0.009-0.036,0.014c-0.017,0.006-0.037,0.015-0.053,0.021
   c-1.403,0.555-2.886,0.569-4.221,0.143c-0.008-0.002-0.021-0.008-0.028-0.01c-0.014-0.003-0.025-0.009-0.039-0.011
   c-2.17-0.697-4.653-0.179-6.379,1.546c-2.463,2.462-2.463,6.454,0,8.915c0.746,0.746,1.627,1.264,2.565,1.559
   c0.043,0.014,0.085,0.025,0.125,0.039c0.915,0.299,1.772,0.809,2.497,1.531c0.007,0.008,0.013,0.013,0.02,0.02
   c0.006,0.008,0.013,0.014,0.021,0.02c0.723,0.726,1.235,1.583,1.533,2.498c0.012,0.039,0.027,0.082,0.039,0.126
   c0.293,0.938,0.811,1.818,1.558,2.563c2.463,2.465,6.453,2.465,8.916,0C20.844,34.429,21.362,31.945,20.666,29.773z"/>
<g>
   <path fill="#E1E7EB" d="M14.554,9.252c0.6-2.642,0.035-5.135-1.278-6.379c0.035-1.374,1.548-2.871,1.548-2.871
       c-1.244-0.065-2.735,1.489-3.246,2.069C9.437,1.855,7.194,4.153,6.454,7.413c-0.778,3.431,0.403,6.625,2.64,7.132
       C11.332,15.052,13.775,12.683,14.554,9.252z"/>
   <path fill="#E1E7EB" d="M24.364,11.156c1.729-2.67,1.882-5.703,0.475-7.184c0.173-1.31,1.532-2.651,1.532-2.651
       c-1.104-0.06-2.399,1.156-3.036,1.836c-1.885-0.365-4.334,1-5.942,3.481c-1.913,2.953-1.906,6.356,0.02,7.605
       C19.34,15.49,22.45,14.108,24.364,11.156z"/>
   <path fill="#E1E7EB" d="M28.748,23.445c2.643-0.6,5.137-0.033,6.38,1.278c1.374-0.032,2.87-1.548,2.87-1.548
       c0.066,1.243-1.488,2.737-2.069,3.247c0.216,2.141-2.081,4.386-5.341,5.126c-3.432,0.776-6.626-0.404-7.131-2.642
       C22.95,26.669,25.318,24.225,28.748,23.445z"/>
   <path fill="#E1E7EB" d="M26.846,13.638c2.671-1.73,5.704-1.883,7.185-0.475c1.309-0.173,2.65-1.533,2.65-1.533
       c0.06,1.103-1.156,2.399-1.836,3.035c0.363,1.885-0.999,4.335-3.479,5.943c-2.954,1.911-6.359,1.904-7.605-0.021
       C22.512,18.662,23.892,15.551,26.846,13.638z"/>
</g>
</g>
</svg>`;

export const svgsixcol = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="40.000000pt" height="18.000000pt" viewBox="0 0 90.000000 33.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,33.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M20 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M170 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M320 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M470 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M620 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M770 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    </g>
    </svg>
    `;

    export const svgthreecol = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="18.000000pt" viewBox="0 0 45.000000 33.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,33.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M20 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M170 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    <path d="M320 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
    </g>
    </svg>
    `;

    export const svgfourcol = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="27.500000pt" height="18.000000pt" viewBox="0 0 60.000000 33.000000"
    preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,33.000000) scale(0.100000,-0.100000)" stroke="none">
   <path d="M20 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
   <path d="M170 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
   <path d="M320 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
   <path d="M470 155 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
   </g>
   </svg>`;