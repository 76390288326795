import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

import Header from './common/headers/header';
import Footer from "./common/footers/footer";

// ThemeSettings
import ThemeSettings from "./common/theme-settings"


class App extends Component {
    render() {
        const { history,footerMenu,isLoading,userInfo } = this.props

        return (
            <div>
                <Header logoName={'harimaufresh_logo.png'}  userInfo={userInfo} history={history} isLoading={isLoading}/>
                {this.props.children}
                <Footer logoName={'harimaufresh_logo.png'} footerMenu={footerMenu} />
                <ThemeSettings />
            </div>
        );
    }
}

export default App;
